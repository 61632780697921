import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 6",
    id: "SB3V2-U6-P48-E1",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page48/E1/Key/answerKey.png",
    isAllowSubmit: false,
    maxLength: 1,
    fontSize: 40,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page48/E1/6.jpg" }],
      [{ url: "img/FriendsPlus/Page48/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page48/E1/2.jpg" },
        { url: "img/FriendsPlus/Page48/E1/3.jpg", input: true, answer: "3" },
        { url: "img/FriendsPlus/Page48/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page48/E1/5.jpg" }],
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB5-U6-P48-E2",
    audio: "Audios/P48/audio-e2-p48.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [
        // Column1
        { url: "img/FriendsPlus/Page48/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page48/E2/2.jpg",
          audioUrl: "Audios/P48/tieude-e2-p48.mp3",
        },
        { url: "img/FriendsPlus/Page48/E2/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page48/E2/4.jpg" }],
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB3V2-U6-P48-E3",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page48/E3/Key/answerKey.png",
    maxLength: 5,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page48/E3/9.jpg" }],
      [{ url: "img/FriendsPlus/Page48/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page48/E3/2.jpg" },
        {
          url: "img/FriendsPlus/Page48/E3/3.jpg",
          input: true,
          answer: "Oanh  ",
        },
        { url: "img/FriendsPlus/Page48/E3/4.jpg" },
        { url: "img/FriendsPlus/Page48/E3/5.jpg", input: true, answer: "Khoa" },
        { url: "img/FriendsPlus/Page48/E3/6.jpg" },
        { url: "img/FriendsPlus/Page48/E3/7.jpg", input: true, answer: "Thi" },
      ],
      [{ url: "img/FriendsPlus/Page48/E3/8.jpg" }],
      [{ url: "img/FriendsPlus/Page48/E2/2.jpg" }],
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SB3V2-U6-P48-E4",
    audio: "",
    video: "",
    component: T6,
    textareaStyle: { width: "100%" },
    hideBtnFooter: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page48/E4/1.jpg' />
          <textarea id="0" rows="5"></textarea>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
