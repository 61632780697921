import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    unit: "Unit 3",
    id: "SB3V2-U3-P26-E1",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 410,
    maxLength: 34,
    exerciseKey: "img/FriendsPlus/Page26GIT /E1/Key/answerKey.png",
    // exerciseKey: "img/FriendsPlus/Page46/E3/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page26/E1/1.jpg'/>   ",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='margin-left: 5mm'>Where is the teddy bear?<br>#</div>
        <img src='img/FriendsPlus/Page26/E1/2.jpg'>
            
          `,
        answer: ["Teddy bear is under the slide"],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB3V2-U3-P26-E2",
    audio: "Audios/P26/audio-e2-p26.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page26/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page26/E2/b.jpg",
          audioUrl: "Audios/P26/tieude-e2-p26.mp3",
        },
        { url: "img/FriendsPlus/Page26/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page26/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page26/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page26/E2/3.jpg",
          audioUrl: "Audios/P26/hinh1.mp3",
        },
        { url: "img/FriendsPlus/Page26/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page26/E2/5.jpg",
          audioUrl: "Audios/P26/hinh2.mp3",
        },
        { url: "img/FriendsPlus/Page26/E2/6.jpg" },
        {
          url: "img/FriendsPlus/Page26/E2/7.jpg",
          audioUrl: "Audios/P26/hinh3.mp3",
        },
        { url: "img/FriendsPlus/Page26/E2/8.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page26/E2/9.jpg" },
        {
          url: "img/FriendsPlus/Page26/E2/10.jpg",
          audioUrl: "Audios/P26/hinh4.mp3",
        },
        { url: "img/FriendsPlus/Page26/E2/11.jpg" },
        {
          url: "img/FriendsPlus/Page26/E2/12.jpg",
          audioUrl: "Audios/P26/hinh5.mp3",
        },
        { url: "img/FriendsPlus/Page26/E2/13.jpg" },
        {
          url: "img/FriendsPlus/Page26/E2/14.jpg",
          audioUrl: "Audios/P26/hinh6.mp3",
        },
        { url: "img/FriendsPlus/Page26/E2/15.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page26/E2/16.jpg" }],
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB3V2-U3-P26-E3",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    questionImage: [],
    exerciseKey: "img/FriendsPlus/Page26/E3/Key/answerKey.png",
    stylesTextInput: { borderBottom: "dash" },
    maxLength: 1,
    inputSize: 50,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page26/E3/1.jpg' />
        <div style='display: flex; margin-top: 30px '>
          <div style='margin:0px 63px 0px 50px'><b>1.</b> The family is at the pool.</div>#
          <div style='margin-left: 100px'><b>3.</b> The Teddy bear is in the pool.</div>#
        </div>
        <div style='display: flex '>
          <div style='margin:0px 0px 0px 50px '><b>2.</b> Her Teddy bear is on the slide.</div>#
          <div style='margin:0px 122px 0px 100px;'><b>4.</b> Linh is happy now</div>#
        </div>
        <img src='img/FriendsPlus/Page26/E1/2.jpg'>

        
        `,
        answer: ["T", "F", "F", "T"],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB3V2-U3-P26-E4",
    audio: "",
    video: "",
    component: T6,
    textareaStyle: { width: 1050 },
    maxLength: 200,

    // exerciseKey: "img/FriendsPlus/Page46/E3/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page26/E4/1.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div><textarea id="0" rows="4" ></textarea><div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
