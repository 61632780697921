import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P62-E1",
    audio: "Audios/P62/audio-e1-p62.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page62/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page62/E1/b.jpg",
          audioUrl: "Audios/P62/tieude-e1-p62.mp3",
        },
        { url: "img/FriendsPlus/Page62/E1/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page62/E1/1.jpg",
          audioUrl: "Audios/P62/washing a car.mp3",
        },
        {
          url: "img/FriendsPlus/Page62/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page62/E1/3.jpg",
          audioUrl: "Audios/P62/brushing my hair.mp3",
        },
        { url: "img/FriendsPlus/Page62/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page62/E1/5.jpg",
          audioUrl: "Audios/P62/taking photos.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P62-E2",
    audio: "Audios/P62/audio-e2-p62.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page62/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page62/E2/2.jpg",
          audioUrl: "Audios/P62/tieude-e2-p62.mp3",
        },
        { url: "img/FriendsPlus/Page62/E2/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page62/E3/2.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P62-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row

      [{ url: "img/FriendsPlus/Page62/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page62/E3/2.jpg" }],
    ],
  },
};

export default json;
