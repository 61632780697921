import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Review 4",
    id: "SB3V2-RV4-P94-E1",
    audio: "",
    video: "",
    maxLength: 8,
    component: D1,
    exerciseKey: "img/FriendsPlus/Page94/E1/Key/answerKey.png",
    fontSize: 28,

    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page94/E1/29.jpg" }],
      [
        { url: "img/FriendsPlus/Page94/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page94/E1/2.jpg",
          input: true,
          answer: "computer",
        },
        { url: "img/FriendsPlus/Page94/E1/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page94/E1/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page94/E1/5.jpg" },
        {
          url: "img/FriendsPlus/Page94/E1/6.jpg",
          input: true,
          answer: "table",
        },
        { url: "img/FriendsPlus/Page94/E1/7.jpg" },
        {
          url: "img/FriendsPlus/Page94/E1/8.jpg",
          input: true,
          answer: "blanket",
        },
        { url: "img/FriendsPlus/Page94/E1/9.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page94/E1/10.jpg" }],
      [
        { url: "img/FriendsPlus/Page94/E1/11.jpg" },
        {
          url: "img/FriendsPlus/Page94/E1/12.jpg",
          input: true,
          answer: "cabinet",
        },
        { url: "img/FriendsPlus/Page94/E1/13.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page94/E1/14.jpg" }],
      [
        { url: "img/FriendsPlus/Page94/E1/15.jpg" },
        { url: "img/FriendsPlus/Page94/E1/16.jpg", input: true, answer: "5" },
        { url: "img/FriendsPlus/Page94/E1/17.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page94/E1/18.jpg" }],
      [
        { url: "img/FriendsPlus/Page94/E1/19.jpg" },
        { url: "img/FriendsPlus/Page94/E1/20.jpg", input: true, answer: "4" },
        { url: "img/FriendsPlus/Page94/E1/21.jpg" },
        { url: "img/FriendsPlus/Page94/E1/22.jpg", input: true, answer: "2" },
        { url: "img/FriendsPlus/Page94/E1/23.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page94/E1/24.jpg" }],
      [
        { url: "img/FriendsPlus/Page94/E1/25.jpg" },
        { url: "img/FriendsPlus/Page94/E1/26.jpg", input: true, answer: "3" },
        { url: "img/FriendsPlus/Page94/E1/27.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page94/E1/28.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Review 4",
    id: "SB3V2-RV4-P94-E2",
    audio: "Audios/P94/audio-e1-p94.mp3",
    video: "",
    component: T6,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page94/E2/Key/answerKey.png",
    inputSize: 150,
    questionImage: [],
    checkUppercase: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and write. <headphone name='151' src='Audios/P94/tieude-e1-p94.mp3'></headphone> <img src='img/FriendsPlus/Page94/E2/1.jpg' width='50px'>",
        color: "",
      },
    ],
    maxLength: 7,
    hintBox: [
      {
        src: ["cabinet", "drawers", "blanket", "shelf", "<s>rug</s> ", "bed"],
        borderColor: "#468dcb",
        width: 600,
      },
    ],
    questions: [
      {
        title: `
            <div style='margin-bottom:20px'><hintBox id='0'></hintBox></div>

             <div style='margin-bottom:20px'><b>1.</b> The dog is on the <u style="color: rgb(82,197,235);">&emsp;rug&emsp;</u></div>        
             <div style='margin-bottom:20px'><b>2. </b> There’s a computer under the #</div>        
             <div style='margin-bottom:20px'><b>3. </b> There are two blankets on the #</div>        
             <div style='margin-bottom:20px'><b>4. </b> There are three books under the #</div>        
             <div style='margin-bottom:20px'><b>5. </b> The audio guide is on the #</div>        
             <div style='margin-bottom:20px'><b>6. </b> The pillow is under the #</div>        
        
        
        
        `,
        answer: ["cabinet", "shelf", "drawers", "bed", "blanket"],
      },
    ],
  },
};

export default json;
