import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "SB3V2-U6-P44-E1",
    audio: "Audios/P44/audio-e1-p44.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page44/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page44/E1/2.jpg",
          audioUrl: "Audios/P44/tieude-e1-p44.mp3",
        },
        { url: "img/FriendsPlus/Page44/E1/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page44/E2/1.jpg",
          audioUrl: "Audios/P44/long.mp3",
        },
        { url: "img/FriendsPlus/Page44/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page44/E2/3.jpg",
          audioUrl: "Audios/P44/short.mp3",
        },
        { url: "img/FriendsPlus/Page44/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page44/E2/5.jpg",
          audioUrl: "Audios/P44/black.mp3",
        },
        { url: "img/FriendsPlus/Page44/E2/6.jpg" },
        {
          url: "img/FriendsPlus/Page44/E2/7.jpg",
          audioUrl: "Audios/P44/curly.mp3",
        },
        { url: "img/FriendsPlus/Page44/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page44/E2/9.jpg",
          audioUrl: "Audios/P44/straight.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB3V2-U6-P44-E2",
    audio: "Audios/P44/audio-e2-p44.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page44/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page44/E2/b.jpg",
          audioUrl: "Audios/P44/tieude-e2-p44.mp3",
        },
        { url: "img/FriendsPlus/Page44/E2/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page44/E2/1.jpg",
          audioUrl: "Audios/P44/long.mp3",
        },
        { url: "img/FriendsPlus/Page44/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page44/E2/3.jpg",
          audioUrl: "Audios/P44/short.mp3",
        },
        { url: "img/FriendsPlus/Page44/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page44/E2/5.jpg",
          audioUrl: "Audios/P44/black.mp3",
        },
        { url: "img/FriendsPlus/Page44/E2/6.jpg" },
        {
          url: "img/FriendsPlus/Page44/E2/7.jpg",
          audioUrl: "Audios/P44/curly.mp3",
        },
        { url: "img/FriendsPlus/Page44/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page44/E2/9.jpg",
          audioUrl: "Audios/P44/straight.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB3V2-U6-P44-E3",
    audio: "Audios/P44/audio-e3-p44.mp3",
    video: "/img/FriendsPlus/Page44/Video/Unit06.mp4",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page44/E3/a.jpg" },
        {
          url: "img/FriendsPlus/Page44/E3/b.jpg",
          audioUrl: "Audios/P44/tieude-e3-p44.mp3",
        },
        { url: "img/FriendsPlus/Page44/E3/c.jpg" },
      ],
      [
        // Column1
        {
          url: "img/FriendsPlus/Page44/E3/1.jpg",
          audioUrl: "Audios/P44/hinh1-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E3/2.jpg",
          audioUrl: "Audios/P44/hinh2-e3.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page44/E3/3.jpg",
          audioUrl: "Audios/P44/hinh3-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E3/4.jpg",
          audioUrl: "Audios/P44/hinh4-e3.mp3",
        },
      ],
    ],
  },
};

export default json;
