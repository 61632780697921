import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P81-E1",
    audio: "Audios/P81/audio-e1-p81.mp3",
    video: "",
    component: UI,
    recorder: true,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page81/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page81/E1/b.jpg",
          audioUrl: "Audios/P81/tieude-e1-p81.mp3",
        },
        { url: "img/FriendsPlus/Page81/E1/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page81/E1/2.jpg" }],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P81-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [{ url: "img/FriendsPlus/Page81/E2/1.jpg" }],
      [{ url: "img/FriendsPlus/Page81/E2/2.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P81-E3",
    audio: "",
    video: "",
    component: T6,

    inputSize: 250,
    hideBtnFooter: true,
    titleImage: "img/FriendsPlus/Page80/E3/1.jpg",
    questionImage: [],
    // hintBox: [
    //   {
    //     src: [
    //       "<s style='color: rgb(0 188 212)'>brushing my hair</s>",
    //       "washing the car",
    //       "making cakes",
    //       "taking photos",
    //     ],
    //     borderColor: "#468dcb",
    //     width: 600,
    //   },
    // ],
    questions: [
      {
        title: `
        <div style='margin-bottom:20px'>My favorite museum is #. The museum</div>
        <div style='margin-bottom:20px'>is in #. I like the #.</div>
        <div style='margin-bottom:20px'>The #is great! The museum is </div>
        <div>open #.</div>
        
        `,
        answer: ["a ", "B ", "b", "b", "a"],
      },
    ],
  },
};

export default json;
