import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P59-E1",
    audio: "Audios/P59/audio-e1-p59.mp3",
    video: "",
    // exerciseKey: "img/FriendsPlus/Page59/E2/Key/answerKey.png",
    component: T6,
    stylesTextInput: {
      borderBottom: "none",
      border: "1px solid gray",
      borderRadius: "5px",
      fontSize: 23,
      width: 35,
      height: 36,
      maxLength: 1,
    },
    isHiddenCheck: true,
    inputSize: 85,
    exerciseKey: "img/FriendsPlus/Page59/E1/Key/answerKey.png",
    maxLength: 7,
    checkUppercase: true,
    // hintBox: [
    //   {
    //     src: [
    //       "am",
    //       "Am",
    //       "are",
    //       "Are",
    //       "aren't",
    //       "aren't",
    //       "Is",
    //       "isn't",
    //       "isn't",
    //       "<s>'m</s>",
    //       "'m not",
    //       "'re",
    //       "'s",
    //     ],
    //     width: "500px",
    //     borderColor: "#77cedf",
    //   },
    // ],
    titleQuestion: [
      {
        num: "1",
        title: `Listen and number <headphone name='92' src='Audios/P59/tieude-e1-p59.mp3'></headphone>
          <img src='img/FriendsPlus/Page59/E1/2.jpg' style='width:100px'/>
          `,
        color: "#234090",
        // star: 1,
      },
    ],
    textAlign: "center",
    questions: [
      {
        title: `
          <div style="position: relative; margin-top: 10px">
            <div><img src='img/FriendsPlus/Page59/E1/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 282px; left: 279px; ">#</div>
              <div style=" position: absolute; top: 36px; left: 316px; ">#</div>
              <div style=" position: absolute; top: 261px; left: 378px; ">#</div>
              <div style=" position: absolute; top: 38px; left: 509px; ">#</div>
              <div style=" position: absolute; top: 33px; left: 667px; ">#</div>
          </div>
        `,
        answer: ["5 b", "3 c", "2 d", "6 e", "4 f"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P59-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page59/E2/1.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page59/E2/2.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P59-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page59/E3/1.jpg" },
      ],
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P59-E4",
    audio: "",
    video: "",
    component: T6,
    // exerciseKey: "img/FriendsPlus/Page9/E2/Key/answerKey.png",
    inputSize: 550,
    checkUppercase: true,
    fontSize: 25,
    checkDuplicated: true,
    hideBtnFooter: true,
    titleImage: "img/FriendsPlus/Page59/E4/1.jpg",
    questions: [
      {
        title: `
          I’m #.
        `,
        answer: [""],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P59-E5",
    audio: "",
    video: "",
    component: T6,
    // exerciseKey: "img/FriendsPlus/Page9/E2/Key/answerKey.png",
    inputSize: 550,
    checkUppercase: true,
    fontSize: 25,
    checkDuplicated: true,
    hideBtnFooter: true,
    titleImage: "img/FriendsPlus/Page59/E5/1.jpg",
    questions: [
      {
        title: `
          She’s / He’s wearing #.
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
