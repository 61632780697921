import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB3V2-U1-P11-E1",
    audio: "Audios/P11/audio.e1.p11.mp3",
    video: "/img/FriendsPlus/Page10/Video/Unit01.mp4",
    component: UI,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page11/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page11/E1/2.jpg",
          audioUrl: "Audios/P11/tieude.e1.p11.mp3",
        },
        { url: "img/FriendsPlus/Page11/E1/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page10/E3/1.jpg",
          audioUrl: "Audios/P10/hinh1.e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E3/2.jpg",
          audioUrl: "Audios/P10/hinh2.e3.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page10/E3/3.jpg",
          audioUrl: "Audios/P10/hinh3.e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E3/4.jpg",
          audioUrl: "Audios/P10/hinh4.e3.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB3V2-U1-P11-E2",
    audio: "Audios/P11/audio.e2.p11.mp3",
    video: "",
    component: UI,
    // recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page11/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page11/E2/5.jpg",
          audioUrl: "Audios/P10/tieude.e2.p11.mp3",
        },
        { url: "img/FriendsPlus/Page11/E2/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page11/E2/1.jpg" }],
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB3V2-U1-P11-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [{ url: "img/FriendsPlus/Page11/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page11/E3/2.jpg" }],
    ],
  },
  4: {
    unit: "Unit 1",
    id: "SB3V2-U1-P11-E4",
    audio: "",
    video: "",
    component: D1,
    maxLength: 4,
    exerciseKey: "img/FriendsPlus/Page11/E4/Key/answerKey.png",
    questionImage: [
      [{ url: "img/FriendsPlus/Page11/E4/16.jpg" }],
      [{ url: "img/FriendsPlus/Page11/E4/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E4/2.jpg" },
        { url: "img/FriendsPlus/Page11/E4/3.jpg", input: true, answer: "my" },
        { url: "img/FriendsPlus/Page11/E4/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page11/E4/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E4/6.jpg" },
        { url: "img/FriendsPlus/Page11/E4/7.jpg", input: true, answer: "your" },
        { url: "img/FriendsPlus/Page11/E4/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page11/E4/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E4/10.jpg" },
        {
          url: "img/FriendsPlus/Page11/E4/11.jpg",
          input: true,
          answer: "my",
        },
        { url: "img/FriendsPlus/Page11/E4/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page11/E4/13.jpg" }],
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SB3V2-U1-P11-E5",
    audio: "img/FriendsPlus/Page40/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [[{ url: "img/FriendsPlus/Page11/E5/1.jpg" }]],
  },
};

export default json;
