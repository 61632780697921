import DesignTypeIn from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  5: {
    unit: "Review 2",
    id: "SB3V2-R2-P51-E2",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page51/E5/Key/answerKey.png",
    maxLength: 20,
    // isAllowSubmit: false,
    fontSize: 26,
    inputHeight: 27,

    question: [],
    checkUppercase: true,
    questionImage: [
      [{ url: "img/FriendsPlus/Page51/E5/19.jpg" }],
      [{ url: "img/FriendsPlus/Page51/E5/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page51/E5/2.jpg" },
        {
          url: "img/FriendsPlus/Page51/E5/3.jpg",
          input: true,
          answer: "Do you like bread",
        },
        { url: "img/FriendsPlus/Page51/E5/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page51/E5/5.jpg" },
        {
          url: "img/FriendsPlus/Page51/E5/6.jpg",
          input: true,
          answer: "No, I don't.",
        },
        { url: "img/FriendsPlus/Page51/E5/7.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page51/E5/8.jpg" }],
      [
        { url: "img/FriendsPlus/Page51/E5/9.jpg" },
        {
          url: "img/FriendsPlus/Page51/E5/10.jpg",
          input: true,
          answer: "Do you like meat",
        },
        { url: "img/FriendsPlus/Page51/E5/11.jpg" },
        {
          url: "img/FriendsPlus/Page51/E5/12.jpg",
          input: true,
          answer: "Do you like rice",
        },
        { url: "img/FriendsPlus/Page51/E5/13.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page51/E5/14.jpg" },
        {
          url: "img/FriendsPlus/Page51/E5/15.jpg",
          input: true,
          answer: "Yes, I do.",
        },
        { url: "img/FriendsPlus/Page51/E5/16.jpg" },
        {
          url: "img/FriendsPlus/Page51/E5/17.jpg",
          input: true,
          answer: "No, I don't.",
        },
        { url: "img/FriendsPlus/Page51/E5/18.jpg" },
      ],
    ],
  },

  6: {
    unit: "Review 2",
    id: "SB3V2-R2-P51-E6",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page51/E6/Key/answerKey.png",
    // isAllowSubmit: false,
    fontSize: 26,
    inputHeight: 27,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page51/E6/8.jpg" }],

      [{ url: "img/FriendsPlus/Page51/E6/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page51/E6/2.jpg" },
        {
          url: "img/FriendsPlus/Page51/E6/3.jpg",
        },
        { url: "img/FriendsPlus/Page51/E6/4.jpg" },
        {
          url: "img/FriendsPlus/Page51/E6/5.jpg",
        },
        { url: "img/FriendsPlus/Page51/E6/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page51/E6/7.jpg" }],
    ],
  },
};
export default json;
