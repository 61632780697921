import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P54-E1",
    audio: "Audios/P54/audio-e1-p53.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page54/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page54/E1/2.jpg",
          audioUrl: "Audios/P54/tieude-e1-p53.mp3",
        },
        { url: "img/FriendsPlus/Page54/E1/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page54/E2/1.jpg",
          audioUrl: "Audios/P54/skirt.mp3",
        },
        { url: "img/FriendsPlus/Page54/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page54/E2/3.jpg",
          audioUrl: "Audios/P54/scarf.mp3",
        },
        { url: "img/FriendsPlus/Page54/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page54/E2/5.jpg",
          audioUrl: "Audios/P54/jeans.mp3",
        },
        { url: "img/FriendsPlus/Page54/E2/6.jpg" },
        {
          url: "img/FriendsPlus/Page54/E2/7.jpg",
          audioUrl: "Audios/P54/boots.mp3",
        },
        { url: "img/FriendsPlus/Page54/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page54/E2/9.jpg",
          audioUrl: "Audios/P54/shirt.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P54-E2",
    audio: "Audios/P54/audio-e2-p53.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page54/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page54/E2/b.jpg",
          audioUrl: "Audios/P54/tieude-e2-p53.mp3",
        },
        { url: "img/FriendsPlus/Page54/E2/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page54/E2/1.jpg",
          audioUrl: "Audios/P54/skirt.mp3",
        },
        { url: "img/FriendsPlus/Page54/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page54/E2/3.jpg",
          audioUrl: "Audios/P54/scarf.mp3",
        },
        { url: "img/FriendsPlus/Page54/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page54/E2/5.jpg",
          audioUrl: "Audios/P54/jeans.mp3",
        },
        { url: "img/FriendsPlus/Page54/E2/6.jpg" },
        {
          url: "img/FriendsPlus/Page54/E2/7.jpg",
          audioUrl: "Audios/P54/boots.mp3",
        },
        { url: "img/FriendsPlus/Page54/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page54/E2/9.jpg",
          audioUrl: "Audios/P54/shirt.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P54-E3",
    audio: "Audios/P54/audio-e3-p53.mp3",
    video: "/img/FriendsPlus/Page54/Video/Unit07.mp4",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page54/E3/a.jpg" },
        {
          url: "img/FriendsPlus/Page54/E3/b.jpg",
          audioUrl: "Audios/P54/tieude-e3-p53.mp3",
        },
        { url: "img/FriendsPlus/Page54/E3/c.jpg" },
      ],
      [
        // Column1
        {
          url: "img/FriendsPlus/Page54/E3/1.jpg",
          audioUrl: "Audios/P54/hinh1-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E3/2.jpg",
          audioUrl: "Audios/P54/hinh2-e3.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page54/E3/3.jpg",
          audioUrl: "Audios/P54/hinh3-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E3/4.jpg",
          audioUrl: "Audios/P54/hinh4-e3.mp3",
        },
      ],
    ],
  },
};

export default json;
