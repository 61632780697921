import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Fluency Time! 4",
    id: "SB3V2-FT4-U97-E1",
    audio: "Audios/P97/audio-e1-p97.mp3",
    video: "",
    component: UI,
    fontSize: 27,
    maxLength: 8,
    isAllowSubmit: true,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page97/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page97/E1/b.jpg",
          audioUrl: "Audios/P97/tieude-e1-p97.mp3",
        },
        { url: "img/FriendsPlus/Page97/E1/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page97/E1/1.jpg",
          audioUrl: "Audios/P97/a whole.mp3",
        },
        {
          url: "img/FriendsPlus/Page97/E1/2.jpg",
          audioUrl: "Audios/P97/a half.mp3",
        },
        {
          url: "img/FriendsPlus/Page97/E1/3.jpg",
          audioUrl: "Audios/P97/a third.mp3",
        },
        {
          url: "img/FriendsPlus/Page97/E1/4.jpg",
          audioUrl: "Audios/P97/a quarter.mp3",
        },
        {
          url: "img/FriendsPlus/Page97/E1/5.jpg",
          audioUrl: "Audios/P97/three quarters.mp3",
        },
      ],
    ],
  },

  2: {
    // Exercise num
    unit: "Fluency Time! 4",
    id: "SB3V2-FT4-U97-E2",
    audio: "Audios/P97/audio-e2-p97.mp3",
    video: "",
    component: UI,
    fontSize: 27,
    maxLength: 8,
    isAllowSubmit: true,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page97/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page97/E2/b.jpg",
          audioUrl: "Audios/P97/tieude-e2-p97.mp3",
        },
        { url: "img/FriendsPlus/Page97/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page97/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page97/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page97/E2/3.jpg",
          audioUrl: "Audios/P97/hinh1.mp3",
        },
        { url: "img/FriendsPlus/Page97/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page97/E2/5.jpg",
          audioUrl: "Audios/P97/hinh2.mp3",
        },
        { url: "img/FriendsPlus/Page97/E2/6.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page97/E2/7.jpg" },
        {
          url: "img/FriendsPlus/Page97/E2/8.jpg",
          audioUrl: "Audios/P97/hinh1.mp3",
        },
        { url: "img/FriendsPlus/Page97/E2/9.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page97/E2/10.jpg" },
        {
          url: "img/FriendsPlus/Page97/E2/11.jpg",
          audioUrl: "Audios/P97/hinh4.mp3",
        },
        { url: "img/FriendsPlus/Page97/E2/12.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page97/E2/13.jpg" },
        {
          url: "img/FriendsPlus/Page97/E2/14.jpg",
          audioUrl: "Audios/P97/hinh3.mp3",
        },
        { url: "img/FriendsPlus/Page97/E2/15.jpg" },
        {
          url: "img/FriendsPlus/Page97/E2/16.jpg",
          audioUrl: "Audios/P97/hinh4.mp3",
        },
        { url: "img/FriendsPlus/Page97/E2/17.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page97/E2/18.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Fluency Time! 4",
    id: "SB3V2-FT4-U97-E3",
    audio: "",
    video: "",
    component: UI,
    fontSize: 27,

    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page97/E3/1.jpg" }],
    ],
  },
};

export default json;
