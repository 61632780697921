import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P60-E1",
    audio: "Audios/P60/audio-e1-p60.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page60/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page60/E1/2.jpg",
          audioUrl: "Audios/P60/tieude-e1-p60.mp3",
        },
        { url: "img/FriendsPlus/Page60/E1/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page60/E2/1.jpg",
          audioUrl: "Audios/P60/play.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E2/2.jpg",
          audioUrl: "Audios/P60/dance.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E2/3.jpg",
          audioUrl: "Audios/P60/sing.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E2/4.jpg",
          audioUrl: "Audios/P60/eat.mp3",
        },

        {
          url: "img/FriendsPlus/Page60/E2/5.jpg",
          audioUrl: "Audios/P60/talk.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E2/6.jpg",
          audioUrl: "Audios/P60/watch.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB3V2-U8-P60-E2",
    audio: "Audios/P60/audio-e2-p60.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page60/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page60/E2/b.jpg",
          audioUrl: "Audios/P60/tieude-e2-p60.mp3",
        },
        { url: "img/FriendsPlus/Page60/E2/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page60/E2/1.jpg",
          audioUrl: "Audios/P60/play.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E2/2.jpg",
          audioUrl: "Audios/P60/dance.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E2/3.jpg",
          audioUrl: "Audios/P60/sing.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E2/4.jpg",
          audioUrl: "Audios/P60/eat.mp3",
        },

        {
          url: "img/FriendsPlus/Page60/E2/5.jpg",
          audioUrl: "Audios/P60/talk.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E2/6.jpg",
          audioUrl: "Audios/P60/watch.mp3",
        },
      ],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P60-E3",
    audio: "Audios/P60/audio-e3-p60.mp3",
    video: "/img/FriendsPlus/Page60/Video/Unit08.mp4",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page60/E3/a.jpg" },
        {
          url: "img/FriendsPlus/Page60/E3/b.jpg",
          audioUrl: "Audios/P60/tieude-e3-p60.mp3",
        },
        { url: "img/FriendsPlus/Page60/E3/c.jpg" },
      ],
      [
        // Column1
        {
          url: "img/FriendsPlus/Page60/E3/1.jpg",
          audioUrl: "Audios/P60/hinh1-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E3/2.jpg",
          audioUrl: "Audios/P60/hinh2-e3.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page60/E3/3.jpg",
          audioUrl: "Audios/P60/hinh3-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E3/4.jpg",
          audioUrl: "Audios/P60/hinh4-e3.mp3",
        },
      ],
    ],
  },
};

export default json;
