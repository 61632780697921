import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P87-E1",
    audio: "/img/FriendsPlus/Page87/Audio/P87-E1.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page87/E1/Key/answerKey.png",
    inputSize: 40,
    maxLength: 1,
    questionImage: [],
    stylesTextInput: {
      height: 40,
      border: "2px solid black",
      borderRadius: "10px",
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and write a or b. <headphone name='140' src='Audios/P87/.mp3'></headphone>",
        color: "#203c8f",
      },
    ],

    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page87/E1/1.jpg' />
        <div style='display:flex;justify-content: space-around;line-height:50px'>
          <div>
            1/ There are six chairs. #<br>
            2/ There is a toy box with lots of toys. #<br>
            3/ There are red shoes on the rug. #<br>
          </div>
          <div style='margin-left:50px'>
            4/ There is a shelf with lots of books. #<br>
            5/ There is a rug on the floor. #<br>
            6/ There is a bike in the room. #<br>
          </div>
        </div>

       
        
        
        
        `,
        answer: ["a", "b", "b", "a", "b", "a"],
      },
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P87-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [{ url: "img/FriendsPlus/Page87/E2/1.jpg" }],
      [{ url: "img/FriendsPlus/Page87/E2/2.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P87-E3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [{ url: "img/FriendsPlus/Page87/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page87/E3/2.jpg" }],
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P87-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page87/E4/Key/answerKey.png",
    inputSize: 40,
    titleImage: "img/FriendsPlus/Page87/E4/1.jpg",
    borderBottom: "das",
    questionImage: [],
    maxLength: 1,
    // hintBox: [
    //   {
    //     src: [
    //       "<s style='color: rgb(0 188 212)'>brushing my hair</s>",
    //       "washing the car",
    //       "making cakes",
    //       "taking photos",
    //     ],
    //     borderColor: "#468dcb",
    //     width: 600,
    //   },
    // ],
    questions: [
      {
        title: `
        <div  style='display:flex;margin:20px'>
          <div style='margin-right:100px'><b>a.</b> How many tables are there#</div>        
          <div style='margin-right:'><b>e. </b> There is one table#</div>        
        </div>
        <div  style='display:flex;margin:20px'>
          <div style='margin-right: 101px'><b>b. </b> How many chairs are there#</div>        
          <div style='margin-right:'><b>f. </b> There are four chairs#</div>        
        </div>
        <div  style='display:flex;margin:20px'>
          <div style='margin-right:76px'><b>c. </b> How many cabinets are there#</div>        
          <div style='margin-right:'><b>g. </b> There are three cabinets#</div>        
        </div>
        <div  style='display:flex;margin:20px'>
          <div style='margin-right:100px'><b>d. </b> How many books are there#</div>        
          <div style='margin-right:'><b>h. </b> There are fifteen books#</div>        
        </div>
        
        
        
        
        `,
        answer: ["?", ".", "?", ".", "?", ".", "?", "."],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P87-E5",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page70/E3/Key/answerKey.png",
    inputSize: 100,
    titleImage: "img/FriendsPlus/Page87/E5/1.jpg",
    questionImage: [],
    hideBtnFooter: true,
    textareaStyle: { width: 700 },
    // hintBox: [
    //   {
    //     src: [
    //       "<s style='color: rgb(0 188 212)'>brushing my hair</s>",
    //       "washing the car",
    //       "making cakes",
    //       "taking photos",
    //     ],
    //     borderColor: "#468dcb",
    //     width: 600,
    //   },
    // ],
    questions: [
      {
        title: `
        
       
        <div style=''>
                    
            <textarea id="0" rows="8"></textarea>       
                   
        </div>
        
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
