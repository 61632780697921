import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "SB3V2-U6-P47-E1",
    audio: "Audios/P47/audio-e1-p47.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page47/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page47/E1/b.jpg",
          audioUrl: "Audios/P47/tieude-e1-p47.mp3",
        },
        { url: "img/FriendsPlus/Page47/E1/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page47/E1/1.jpg",
          audioUrl: "Audios/P47/K.mp3",
        },
        {
          url: "img/FriendsPlus/Page47/E1/2.jpg",
          audioUrl: "Audios/P47/kite.mp3",
        },
        {
          url: "img/FriendsPlus/Page47/E1/3.jpg",
          audioUrl: "Audios/P47/L.mp3",
        },
        {
          url: "img/FriendsPlus/Page47/E1/4.jpg",
          audioUrl: "Audios/P47/lion.mp3",
        },
        {
          url: "img/FriendsPlus/Page47/E1/5.jpg",
          audioUrl: "Audios/P47/M.mp3",
        },
        {
          url: "img/FriendsPlus/Page47/E1/6.jpg",
          audioUrl: "Audios/P47/mom.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 6",
    id: "SB3V2-U6-P47-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [
        // Column2
        { url: "img/FriendsPlus/Page47/E2/1.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page47/E2/2.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 6",
    id: "SB3V2-U6-P47-E3",
    audio: "Audios/P47/audio-e3-p47.mp3",
    video: "",
    component: DesignUnderLine,
    // component: DesignUnderLine1,
    totalInput: 5,
    exerciseKey: "img/FriendsPlus/Page47/E3/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page47/E3/37.jpg" },
        {
          url: "img/FriendsPlus/Page47/E3/38.jpg",
          audioUrl: "Audios/P47/tieude-e3-p47.mp3",
        },
        { url: "img/FriendsPlus/Page47/E3/39.jpg" },
      ],
      [
        // row1
        { url: "img/FriendsPlus/Page47/E3/1.jpg" },
      ],
      [
        // row2
        { url: "img/FriendsPlus/Page47/E3/2.jpg" },
        { url: "img/FriendsPlus/Page47/E3/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page47/E3/4.jpg" },
        { url: "img/FriendsPlus/Page47/E3/5.jpg", input: 1 },
        { url: "img/FriendsPlus/Page47/E3/6.jpg" },
        { url: "img/FriendsPlus/Page47/E3/7.jpg", input: 1 },
        { url: "img/FriendsPlus/Page47/E3/8.jpg" },
      ],
      [
        // row3
        { url: "img/FriendsPlus/Page47/E3/9.jpg" },
        { url: "img/FriendsPlus/Page47/E3/10.jpg", input: 2 },
        { url: "img/FriendsPlus/Page47/E3/11.jpg" },
        { url: "img/FriendsPlus/Page47/E3/12.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page47/E3/13.jpg" },
        { url: "img/FriendsPlus/Page47/E3/14.jpg", input: 2 },
        { url: "img/FriendsPlus/Page47/E3/15.jpg" },
      ],
      [
        // row4
        { url: "img/FriendsPlus/Page47/E3/16.jpg" },
        { url: "img/FriendsPlus/Page47/E3/17.jpg", input: 3 },
        { url: "img/FriendsPlus/Page47/E3/18.jpg" },
        { url: "img/FriendsPlus/Page47/E3/19.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page47/E3/20.jpg" },
        { url: "img/FriendsPlus/Page47/E3/21.jpg", input: 3 },
        { url: "img/FriendsPlus/Page47/E3/22.jpg" },
      ],
      [
        // row5
        { url: "img/FriendsPlus/Page47/E3/23.jpg" },
        { url: "img/FriendsPlus/Page47/E3/24.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page47/E3/25.jpg" },
        { url: "img/FriendsPlus/Page47/E3/26.jpg", input: 4 },
        { url: "img/FriendsPlus/Page47/E3/27.jpg" },
        { url: "img/FriendsPlus/Page47/E3/28.jpg", input: 4 },
        { url: "img/FriendsPlus/Page47/E3/29.jpg" },
      ],
      [
        // row6
        { url: "img/FriendsPlus/Page47/E3/30.jpg" },
        { url: "img/FriendsPlus/Page47/E3/31.jpg", input: 5 },
        { url: "img/FriendsPlus/Page47/E3/32.jpg" },
        { url: "img/FriendsPlus/Page47/E3/33.jpg", input: 5 },
        { url: "img/FriendsPlus/Page47/E3/34.jpg" },
        { url: "img/FriendsPlus/Page47/E3/35.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page47/E3/36.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 6",
    id: "SB3V2-U6-P47-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [
        // Column2
        { url: "img/FriendsPlus/Page47/E4/1.jpg" },
      ],
    ],
  },
};

export default json;
