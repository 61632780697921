import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    unit: "Unit 5",
    id: "SB3V2-U5-P38-E1",
    audio: "Audios/P38/audio-e1-p38.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page38/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page38/E1/2.jpg",
          audioUrl: "Audios/P38/tieude-e1-p38.mp3",
        },
        { url: "img/FriendsPlus/Page38/E1/3.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page38/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page38/E1/5.jpg",
          audioUrl: "Audios/P38/rice.mp3",
        },
        { url: "img/FriendsPlus/Page38/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page38/E1/7.jpg",
          audioUrl: "Audios/P38/meat.mp3",
        },
        { url: "img/FriendsPlus/Page38/E1/8.jpg" },
        {
          url: "img/FriendsPlus/Page38/E1/9.jpg",
          audioUrl: "Audios/P38/carrots.mp3",
        },
        { url: "img/FriendsPlus/Page38/E1/10.jpg" },
        {
          url: "img/FriendsPlus/Page38/E1/11.jpg",
          audioUrl: "Audios/P38/yogurt.mp3",
        },
        { url: "img/FriendsPlus/Page38/E1/12.jpg" },
        {
          url: "img/FriendsPlus/Page38/E1/13.jpg",
          audioUrl: "Audios/P38/bread.mp3",
        },
        { url: "img/FriendsPlus/Page38/E1/14.jpg" },
      ],
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB3V2-U5-P38-E2",
    audio: "Audios/P38/audio-e2-p38.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page38/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page38/E2/2.jpg",
          audioUrl: "Audios/P38/tieude-e2-p38.mp3",
        },
        { url: "img/FriendsPlus/Page38/E2/3.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page38/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page38/E1/5.jpg",
          audioUrl: "Audios/P38/rice.mp3",
        },
        { url: "img/FriendsPlus/Page38/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page38/E1/7.jpg",
          audioUrl: "Audios/P38/meat.mp3",
        },
        { url: "img/FriendsPlus/Page38/E1/8.jpg" },
        {
          url: "img/FriendsPlus/Page38/E1/9.jpg",
          audioUrl: "Audios/P38/carrots.mp3",
        },
        { url: "img/FriendsPlus/Page38/E1/10.jpg" },
        {
          url: "img/FriendsPlus/Page38/E1/11.jpg",
          audioUrl: "Audios/P38/yogurt.mp3",
        },
        { url: "img/FriendsPlus/Page38/E1/12.jpg" },
        {
          url: "img/FriendsPlus/Page38/E1/13.jpg",
          audioUrl: "Audios/P38/bread.mp3",
        },
        { url: "img/FriendsPlus/Page38/E1/14.jpg" },
      ],
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB3V2-U5-P38-E3",
    audio: "Audios/P38/audio-e3-p38.mp3",
    video: "Videos/Video-e3-p38.mp4",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page38/E3/a.jpg" },
        {
          url: "img/FriendsPlus/Page38/E3/b.jpg",
          audioUrl: "Audios/P38/tieude-e3-p38.mp3",
        },
        { url: "img/FriendsPlus/Page38/E3/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page38/E3/1.jpg",
          audioUrl: "Audios/P38/hinh1-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E3/2.jpg",
          audioUrl: "Audios/P38/hinh2-e3.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page38/E3/3.jpg",
          audioUrl: "Audios/P38/hinh3-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E3/4.jpg",
          audioUrl: "Audios/P38/hinh4-e3.mp3",
        },
      ],
    ],
  },
};

export default json;
