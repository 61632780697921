import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "SB3V2-U4-P33-E1",
    audio: "Audios/P32/audio-e3-p32.mp3",
    video: "/img/FriendsPlus/Page32/Video/Unit04.mp4",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Look at the calendar again. Talk about Hoa.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page33/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page33/E1/2.jpg",
          audioUrl: "Audios/P32/tieude-e3-p32.mp3",
        },
        { url: "img/FriendsPlus/Page33/E1/3.jpg" },
      ],
      [
        // Column1
        {
          url: "img/FriendsPlus/Page32/E3/1.jpg",
          audioUrl: "Audios/P32/hinh1-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page32/E3/2.jpg",
          audioUrl: "Audios/P32/hinh2-e3.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page32/E3/3.jpg",
          audioUrl: "Audios/P32/hinh3-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page32/E3/4.jpg",
          audioUrl: "Audios/P32/hinh4-e3.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 4",
    id: "SB3V2-U4-P33-E2",
    audio: "Audios/P33/audio-e2-p33.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page33/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page33/E2/b.jpg",
          audioUrl: "Audios/P33/tieude-e2-p33.mp3",
        },
        { url: "img/FriendsPlus/Page33/E2/c.jpg" },
      ],
      // Row
      [
        {
          url: "img/FriendsPlus/Page33/E2/1.jpg",
          audioUrl: "Audios/P33/hinh1-e2.mp3",
        },
        {
          url: "img/FriendsPlus/Page33/E2/2.jpg",
          audioUrl: "Audios/P33/hinh2-e2.mp3",
        },
        {
          url: "img/FriendsPlus/Page33/E2/3.jpg",
          audioUrl: "Audios/P33/hinh3-e2.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 4",
    id: "SB3V2-U4-P33-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page33/E3/1.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page33/E3/2.jpg" }],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 4",
    id: "SB3V2-U4-P33-E4",
    audio: "",
    video: "",
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page33/E4/Key/answerKey.png",
    component: D1,
    maxLength: 10,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page33/E4/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page33/E4/1.jpg" }],
      [
        // Column1
        { url: "img/FriendsPlus/Page33/E4/2.jpg" },
        {
          url: "img/FriendsPlus/Page33/E4/3.jpg",
          input: true,
          answer: "don't like",
        },
        { url: "img/FriendsPlus/Page33/E4/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page33/E4/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page33/E4/6.jpg" },
        {
          url: "img/FriendsPlus/Page33/E4/7.jpg",
          input: true,
          answer: "don't like",
        },
        { url: "img/FriendsPlus/Page33/E4/8.jpg" },
        { url: "img/FriendsPlus/Page33/E4/9.jpg", input: true, answer: "like" },
        { url: "img/FriendsPlus/Page33/E4/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page33/E4/11.jpg" }],
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 4",
    id: "SB3V2-U4-P33-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page33/E5/1.jpg" },
      ],
    ],
  },
};

export default json;
