import _ from 'lodash';
import { Fragment, useCallback, useEffect, useState } from 'react';

function Write({ answers, commonStyles, id, isDone, specificStyle, writeRef }) {
  console.log('🚀 ~ writeRef', writeRef);
  const [text, setText] = useState(undefined);
  const [listBoolean, setListBoolean] = useState([]);

  const handleSubmit = useCallback((answers, userAnswers) => {
    const listBoolean = userAnswers.map((x) => answers[x.id] === x.text);
    setListBoolean(listBoolean);

    const correctAnswer = _.countBy(listBoolean).true ?? 0;
    const percent = parseInt((correctAnswer / answers.length) * 100);
    const resultString = `${correctAnswer}/${answers.length}`;
    const star = percent / 20;

    return { percent, resultString, star, userAnswers };
  }, []);

  useEffect(() => {
    // Object.assign(writeRef.current, {
    //   submit: handleSubmit(answers, writeRef.current.userAnswers),
    // });
    writeRef.current.submit = handleSubmit(answers, writeRef.current.userAnswers);
  }, [answers, handleSubmit, writeRef]);

  useEffect(() => {
    if (text !== undefined) {
      const result = writeRef.userAnswers.findIndex((x) => x.id === id);
      if (result !== -1) {
        writeRef.userAnswers[result].text = text;
      } else {
        writeRef.userAnswers = [...writeRef.userAnswers, { id, text }];
      }
    }
  }, [id, text, writeRef]);

  return (
    <Fragment>
      <input
        onChange={(e) => setText(e.target.value)}
        style={{
          border: 'none',
          borderBottom: '1px dashed',
          color: isDone ? (listBoolean[id] ? 'green' : 'red') : 'black',
          fontSize: 24,
          marginBottom: 10,
          padding: 'none',
          ...commonStyles,
          ...specificStyle,
        }}
      />
    </Fragment>
  );
}

export default Write;
