import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    unit: "Stater",
    id: "SB3V2-Stater-P5-E1",
    audio: "Audios/P4/audio.e2.p4.mp3",
    video: "/img/FriendsPlus/Page4/Video/Starter.mp4",
    component: UI,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page5/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page5/E1/2.jpg",
          audioUrl: "Audios/P5/audio.e1.p5.mp3",
        },
        { url: "img/FriendsPlus/Page5/E1/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page4/E2/1.jpg",
          audioUrl: "Audios/P4/hinh1.e2.p4.mp3",
        },
        { url: "img/FriendsPlus/Page4/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page4/E2/3.jpg",
          audioUrl: "Audios/P4/hinh2.e2.p4.mp3",
        },
      ],
      [{ url: "img/FriendsPlus/Page4/E2/4.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page4/E2/5.jpg",
          audioUrl: "Audios/P4/hinh3.e2.p4.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Stater",
    id: "SB3V2-Stater-P5-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [{ url: "img/FriendsPlus/Page5/E2/1.jpg" }],
      [{ url: "img/FriendsPlus/Page5/E2/2.jpg" }],
    ],
  },
  3: {
    unit: "Stater",
    id: "SB3V2-Stater-P5-E3",
    audio: "Audios/P5/audio.e3.p4.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page5/E3/1.jpg" },
        {
          url: "img/FriendsPlus/Page5/E3/2.jpg",
          audioUrl: "Audios/P5/tieude.e3.p5.mp3",
        },
        { url: "img/FriendsPlus/Page5/E3/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page5/E3/4.jpg" }],
    ],
  },
};

export default json;
