import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P71-E1",
    audio: "Audios/P71/audio-e1-p71.mp3",
    video: "",
    component: UI,
    recorder: true,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page71/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page71/E1/b.jpg",
          audioUrl: "Audios/P71/tieude-e1-p71.mp3",
        },
        { url: "img/FriendsPlus/Page71/E1/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page71/E1/2.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P71-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,

    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page71/E2/1.jpg" }],
      [{ url: "img/FriendsPlus/Page71/E2/2.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P71-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,

    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page71/E3/1.jpg" }],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P71-E4",
    audio: "",
    video: "",
    component: T6,
    // exerciseKey: " ",
    inputSize: 270,
    hideBtnFooter: true,
    titleImage: "img/FriendsPlus/Page71/E4/1.jpg",
    questionImage: [],
    questions: [
      {
        title: `
        
        <div style='margin-bottom:10px'><b>1.</b> I can #.</div>
        <div style='margin-bottom:10px'><b>2.</b> She can #.</div>
        <div style='margin-bottom:10px'><b>3.</b> He #.</div>
        <div style='margin-bottom:10px'><b>4.</b> #.</div>
        
        `,
        answer: ["No", "Yes", "No", "Yes"],
      },
    ],
  },
};

export default json;
