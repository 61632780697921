import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P69-E1",
    audio: "Audios/P69/audio-e1-p69.mp3",
    video: "",
    component: UI,
    recorder: true,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page69/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page69/E1/b.jpg",
          audioUrl: "Audios/P69/audio-e2-p69.mp3",
        },
        { url: "img/FriendsPlus/Page69/E1/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page69/E1/1.jpg",
          audioUrl: "Audios/P69/ai.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E1/2.jpg",
          audioUrl: "Audios/P69/rain.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E1/3.jpg",
          audioUrl: "Audios/P69/train.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E1/4.jpg",
          audioUrl: "Audios/P69/y.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E1/5.jpg",
          audioUrl: "Audios/P69/fly.mp3",
        },
        {
          url: "img/FriendsPlus/Page69/E1/6.jpg",
          audioUrl: "Audios/P69/sky.mp3",
        },
      ],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P69-E2",
    audio: "Audios/P69/audio-e2-p69.mp3",

    video: "",
    component: UI,
    recorder: true,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page69/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page69/E2/b.jpg",
          audioUrl: "Audios/P69/tieude-e2-p69.mp3",
        },
        { url: "img/FriendsPlus/Page69/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page69/E2/2.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P69-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page69/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page69/E2/2.jpg" }],
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P69-E4",
    audio: "",
    video: "",
    component: D1,
    padding: 0,
    textAlign: "center",
    maxLength: 5,
    // maxLength: 1,
    exerciseKey: "img/FriendsPlus/Page69/E4/Key/answerKey.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page69/E4/13.jpg" }],
      [{ url: "img/FriendsPlus/Page69/E4/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page69/E4/2.jpg" },
        { url: "img/FriendsPlus/Page69/E4/3.jpg", input: true, answer: "rain" },
        { url: "img/FriendsPlus/Page69/E4/4.jpg" },
        { url: "img/FriendsPlus/Page69/E4/5.jpg", input: true, answer: "fly" },
        { url: "img/FriendsPlus/Page69/E4/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page69/E4/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page69/E4/8.jpg" },
        { url: "img/FriendsPlus/Page69/E4/9.jpg", input: true, answer: "sky" },
        { url: "img/FriendsPlus/Page69/E4/10.jpg" },
        {
          url: "img/FriendsPlus/Page69/E4/11.jpg",
          input: true,
          answer: "train",
        },
        { url: "img/FriendsPlus/Page69/E4/12.jpg" },
      ],
    ],
  },

  5: {
    // Exercise num
    unit: "Unit 9",
    id: "SB3V2-U9-P69-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page69/E5/1.jpg" }],
    ],
  },
};

export default json;
