import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 2",
    id: "SB3V2-U2-P20-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page20/E1/1.jpg" }],
      [{ url: "img/FriendsPlus/Page20/E2/4.jpg" }],
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB3V2-U2-P20-E2",
    audio: "Audios/P20/audio.e2.p20.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page20/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page20/E2/2.jpg",
          audioUrl: "Audios/P20/tieude.e2.p20.mp3",
        },
        { url: "img/FriendsPlus/Page20/E2/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page20/E2/4.jpg" }],
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB3V2-U2-P20-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page20/E3/Key/answerKey.png",
    component: T6,
    stylesTextInput: { borderBottom: "dash" },
    inputSize: 170,
    maxLength: 8,
    textAlign: "center",
    questionImage: [],
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page20/E3/1.jpg' />
        <div style='display: flex'>
          <div style='margin: 0px 50px'>
             <div style='margin-bottom:20px'><img src='img/FriendsPlus/Page20/E3/2.jpg' /></div>
             <div style='margin-bottom:20px'><b>2.</b>#</div>
             <div style='margin-bottom:20px'><b>3.</b>#</div>
             <div style='margin-bottom:20px'><b>4.</b>#</div>
           <div style='margin'><b>5.</b>#</div>
          </div>
          <div><img src='img/FriendsPlus/Page20/E2/4.jpg' /></div>
        </div>
        
        `,
        answer: ["pencil", "blue pen", "pink pen", "eraser"],
      },
    ],
  },
};

export default json;
