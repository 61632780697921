import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 1",
    id: "SB3V2-U1-P10-E1",
    audio: "Audios/P10/audio.e1.p10.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page10/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page10/E1/2.jpg",
          audioUrl: "Audios/P10/tieude.e1.p10.mp3",
        },
        { url: "img/FriendsPlus/Page10/E1/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page10/E2/1.jpg",
          audioUrl: "Audios/P10/doll.mp3",
        },
        { url: "img/FriendsPlus/Page10/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/3.jpg",
          audioUrl: "Audios/P10/ball.mp3",
        },
        { url: "img/FriendsPlus/Page10/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/5.jpg",
          audioUrl: "Audios/P10/taddybear.mp3",
        },
        { url: "img/FriendsPlus/Page10/E2/6.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/7.jpg",
          audioUrl: "Audios/P10/car.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB3V2-U1-P10-E2",
    audio: "Audios/P10/audio.e2.p10.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page10/E2/10.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/11.jpg",
          audioUrl: "Audios/P10/tieude.e2.p10.mp3",
        },
        { url: "img/FriendsPlus/Page10/E2/12.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page10/E2/1.jpg",
          audioUrl: "Audios/P10/doll.mp3",
        },
        { url: "img/FriendsPlus/Page10/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/3.jpg",
          audioUrl: "Audios/P10/ball.mp3",
        },
        { url: "img/FriendsPlus/Page10/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/5.jpg",
          audioUrl: "Audios/P10/taddybear.mp3",
        },
        { url: "img/FriendsPlus/Page10/E2/6.jpg" },
        {
          url: "img/FriendsPlus/Page10/E2/7.jpg",
          audioUrl: "Audios/P10/car.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB3V2-U1-P10-E3",
    audio: "Audios/P10/audio.e3.p10.mp3",
    video: "/img/FriendsPlus/Page10/Video/Unit01.mp4",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page10/E3/7.jpg" },
        {
          url: "img/FriendsPlus/Page10/E3/8.jpg",
          audioUrl: "Audios/P10/tieude.e3.p10.mp3",
        },
        { url: "img/FriendsPlus/Page10/E3/9.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page10/E3/1.jpg",
          audioUrl: "Audios/P10/hinh1.e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E3/2.jpg",
          audioUrl: "Audios/P10/hinh2.e3.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page10/E3/3.jpg",
          audioUrl: "Audios/P10/hinh3.e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E3/4.jpg",
          audioUrl: "Audios/P10/hinh4.e3.mp3",
        },
      ],
    ],
  },
};

export default json;
