import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DrawColor from "../../components/ExcerciseTypes/DrawColor";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P63-E1",
    audio: "Audios/P63/audio-e1-p63.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page63/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page63/E1/b.jpg",
          audioUrl: "Audios/P63/tieude-e1-p63.mp3",
        },
        { url: "img/FriendsPlus/Page63/E1/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page63/E1/1.jpg",
          audioUrl: "Audios/P63/Dr.mp3",
        },
        {
          url: "img/FriendsPlus/Page63/E1/2.jpg",
          audioUrl: "Audios/P63/drum.mp3",
        },
        { url: "img/FriendsPlus/Page63/E1/3.jpg" },
        {
          url: "img/FriendsPlus/Page63/E1/4.jpg",
          audioUrl: "Audios/P63/Tr.mp3",
        },
        {
          url: "img/FriendsPlus/Page63/E1/5.jpg",
          audioUrl: "Audios/P63/Truck.mp3",
        },
        { url: "img/FriendsPlus/Page63/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page63/E1/7.jpg",
          audioUrl: "Audios/P63/Cr.mp3",
        },
        {
          url: "img/FriendsPlus/Page63/E1/8.jpg",
          audioUrl: "Audios/P63/Crayon.mp3",
        },
      ],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P63-E2",
    audio: "Audios/P63/audio-e2-p63.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page63/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page63/E2/b.jpg",
          audioUrl: "Audios/P63/tieude-e2-p63.mp3",
        },
        { url: "img/FriendsPlus/Page63/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page63/E2/2.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P63-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page63/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page63/E2/2.jpg" }],
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P63-E4",
    audio: "Audios/P63/audio-e3-p63.mp3",
    video: "",
    component: D1,
    // textAlign: "center",
    fontSize: 27,
    exerciseKey: "img/FriendsPlus/Page63/E4/Key/answerKey.png",
    maxLength: 6,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page63/E4/a.jpg" },
        {
          url: "img/FriendsPlus/Page63/E4/b.jpg",
          audioUrl: "Audios/P63/tieude-e3-p63.mp3",
        },
        { url: "img/FriendsPlus/Page63/E4/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page63/E4/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page63/E4/2.jpg" },
        {
          url: "img/FriendsPlus/Page63/E4/3.jpg",
          input: true,
          answer: "truck",
        },
        { url: "img/FriendsPlus/Page63/E4/4.jpg" },
        { url: "img/FriendsPlus/Page63/E4/5.jpg", input: true, answer: "drum" },
        { url: "img/FriendsPlus/Page63/E4/6.jpg" },
        {
          url: "img/FriendsPlus/Page63/E4/7.jpg",
          input: true,
          answer: "crayon",
        },
        { url: "img/FriendsPlus/Page63/E4/8.jpg" },
      ],
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P63-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page63/E5/1.jpg" }],
    ],
  },
};

export default json;
