import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P82-E1",
    audio: "Audios/P82/audio-e1-p82.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page82/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page82/E1/b.jpg",
          audioUrl: "Audios/P82/tieude-e1-p82.mp3",
        },
        { url: "img/FriendsPlus/Page82/E1/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page82/E2/1.jpg",
          audioUrl: "Audios/P82/rug.mp3",
        },
        { url: "img/FriendsPlus/Page82/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page82/E2/3.jpg",
          audioUrl: "Audios/P82/cabinet.mp3",
        },
        { url: "img/FriendsPlus/Page82/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page82/E2/5.jpg",
          audioUrl: "Audios/P82/shelf.mp3",
        },
        { url: "img/FriendsPlus/Page82/E2/6.jpg" },
        {
          url: "img/FriendsPlus/Page82/E2/7.jpg",
          audioUrl: "Audios/P82/pillow.mp3",
        },
        { url: "img/FriendsPlus/Page82/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page82/E2/9.jpg",
          audioUrl: "Audios/P82/blanket.mp3",
        },
      ],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P82-E2",
    audio: "Audios/P82/audio-e2-p82.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page82/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page82/E2/b.jpg",
          audioUrl: "Audios/P82/tieude-e2-p82.mp3",
        },
        { url: "img/FriendsPlus/Page82/E2/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page82/E2/1.jpg",
          audioUrl: "Audios/P82/rug.mp3",
        },
        { url: "img/FriendsPlus/Page82/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page82/E2/3.jpg",
          audioUrl: "Audios/P82/cabinet.mp3",
        },
        { url: "img/FriendsPlus/Page82/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page82/E2/5.jpg",
          audioUrl: "Audios/P82/shelf.mp3",
        },
        { url: "img/FriendsPlus/Page82/E2/6.jpg" },
        {
          url: "img/FriendsPlus/Page82/E2/7.jpg",
          audioUrl: "Audios/P82/pillow.mp3",
        },
        { url: "img/FriendsPlus/Page82/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page82/E2/9.jpg",
          audioUrl: "Audios/P82/blanket.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P82-E3",
    audio: "Audios/P82/audio-e3-p82.mp3",
    video: "Videos/Uni/.mp4",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '4', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page82/E3/a.jpg" },
        {
          url: "img/FriendsPlus/Page82/E3/b.jpg",
          audioUrl: "Audios/P82/tieude-e3-p82.mp3",
        },
        { url: "img/FriendsPlus/Page82/E3/c.jpg" },
      ],
      [
        // Column1
        {
          url: "img/FriendsPlus/Page82/E3/1.jpg",
          audioUrl: "Audios/P82/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E3/2.jpg",
          audioUrl: "Audios/P82/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page82/E3/3.jpg",
          audioUrl: "Audios/P82/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E3/4.jpg",
          audioUrl: "Audios/P82/hinh4.mp3",
        },
      ],
    ],
  },
};

export default json;
