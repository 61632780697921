import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Fluency Time! 4",
    id: "SB3V2-FT4-P96-E1",
    audio: "Audios/P96/audio-e1-p96.mp3",
    video: "",
    maxLength: 8,
    component: UI,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page96/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page96/E1/b.jpg",
          audioUrl: "Audios/P96/tieude-e1-p96.mp3",
        },
        { url: "img/FriendsPlus/Page96/E1/c.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page96/E1/1.jpg",
          audioUrl: "Audios/P96/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page96/E1/2.jpg",
          audioUrl: "Audios/P96/hinh2.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Fluency Time! 4",
    id: "SB3V2-FT4-P96-E2",
    audio: "Audios/P96/audio-e2-p96.mp3",
    video: "",
    maxLength: 1,
    component: D1,
    exerciseKey: "img/FriendsPlus/Page96/E2/Key/answerKey.png",
    fontSize: 26,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page96/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page96/E2/b.jpg",
          audioUrl: "Audios/P96/tieude-e2-p96.mp3",
        },
        { url: "img/FriendsPlus/Page96/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page96/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page96/E2/2.jpg" },
        { url: "img/FriendsPlus/Page96/E2/3.jpg", input: true, answer: "4 " },
        { url: "img/FriendsPlus/Page96/E2/4.jpg" },
        { url: "img/FriendsPlus/Page96/E2/5.jpg", input: true, answer: "2 " },
        { url: "img/FriendsPlus/Page96/E2/6.jpg" },
        { url: "img/FriendsPlus/Page96/E2/7.jpg", input: true, answer: "3 " },
        { url: "img/FriendsPlus/Page96/E2/8.jpg" },
        { url: "img/FriendsPlus/Page96/E2/9.jpg", input: true, answer: "1 " },
        { url: "img/FriendsPlus/Page96/E2/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page96/E2/11.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Fluency Time! 4",
    id: "SB3V2-FT4-P96-E3",
    audio: "",
    video: "",
    maxLength: 8,
    component: UI,

    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page96/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page96/E3/2.jpg" }],
    ],
  },
};

export default json;
