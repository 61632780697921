import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

import DrawColor from "../../components/ExcerciseTypes/DrawColor";

const json = {
  1: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P77-E3",
    audio: "Audios/P76/audio-e3-p76.mp3",
    video: "Videos/Uni/audio-e3-p76.mp4",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page77/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page77/E1/b.jpg",
          audioUrl: "Audios/P76/tieude-e3-p76.mp3",
        },
        { url: "img/FriendsPlus/Page77/E1/c.jpg" },
      ],
      [
        // Column1
        {
          url: "img/FriendsPlus/Page76/E3/1.jpg",
          audioUrl: "Audios/P76/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E3/2.jpg",
          audioUrl: "Audios/P76/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page76/E3/3.jpg",
          audioUrl: "Audios/P76/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E3/4.jpg",
          audioUrl: "Audios/P76/hinh4.mp3",
        },
      ],
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P76-E2",
    audio: "Audios/P77/audio-e2-p77.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page77/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page77/E2/b.jpg",
          audioUrl: "Audios/P77/tieude-e2-p77.mp3",
        },
        { url: "img/FriendsPlus/Page77/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page77/E2/1.jpg" }],

      [
        { url: "img/FriendsPlus/Page77/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page77/E2/3.jpg",
          audioUrl: "Audios/P77/hinh1.mp3",
        },
        { url: "img/FriendsPlus/Page77/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page77/E2/5.jpg",
          audioUrl: "Audios/P77/hinh2.mp3",
        },
        { url: "img/FriendsPlus/Page77/E2/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page77/E2/7.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P77-E3",
    audio: "Audios/P77/audio-e3-p77.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page77/E3/a.jpg" },
        {
          url: "img/FriendsPlus/Page77/E3/b.jpg",
          audioUrl: "Audios/P77/tieude-e3-p77.mp3",
        },
        { url: "img/FriendsPlus/Page77/E3/c.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page77/E3/1.jpg" },
        { url: "img/FriendsPlus/Page77/E3/2.jpg" },
        { url: "img/FriendsPlus/Page77/E3/3.jpg" },
        { url: "img/FriendsPlus/Page77/E3/4.jpg" },
        { url: "img/FriendsPlus/Page77/E3/5.jpg" },
      ],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P77-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page77/E4/1.jpg" }],
      [{ url: "img/FriendsPlus/Page77/E4/2.jpg" }],
    ],
  },

  5: {
    // Exercise num
    unit: "Unit 10",
    id: "SB3V2-U10-P77-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page77/E5/1.jpg" },
      ],
    ],
  },
};

export default json;
