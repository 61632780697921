import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Stater",
    id: "SB3V2-Stater-P4-E1",
    audio: "Audios/P4/audio.e1.p4.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page4/E1/8.jpg" },
        {
          url: "img/FriendsPlus/Page4/E1/9.jpg",
          audioUrl: "Audios/P4/tieude.e1.p4.mp3",
        },
        { url: "img/FriendsPlus/Page4/E1/10.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page4/E1/1.jpg",
          audioUrl: "Audios/P4/Rosy.mp3",
        },
        { url: "img/FriendsPlus/Page4/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page4/E1/3.jpg",
          audioUrl: "Audios/P4/Tim.mp3",
        },
        { url: "img/FriendsPlus/Page4/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page4/E1/5.jpg",
          audioUrl: "Audios/P4/Billy.mp3",
        },
        { url: "img/FriendsPlus/Page4/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page4/E1/7.jpg",
          audioUrl: "Audios/P4/Miss Jones.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Stater",
    id: "SB3V2-Stater-P4-E2",
    audio: "Audios/P4/audio.e2.p4.mp3",
    video: "/img/FriendsPlus/Page4/Video/Starter.mp4",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page4/E2/7.jpg" },
        {
          url: "img/FriendsPlus/Page4/E2/8.jpg",
          audioUrl: "Audios/P4/tieude.e2.p4.mp3",
        },
        { url: "img/FriendsPlus/Page4/E2/9.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page4/E2/1.jpg",
          audioUrl: "Audios/P4/hinh1.e2.p4.mp3",
        },
        { url: "img/FriendsPlus/Page4/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page4/E2/3.jpg",
          audioUrl: "Audios/P4/hinh2.e2.p4.mp3",
        },
      ],
      [{ url: "img/FriendsPlus/Page4/E2/4.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page4/E2/5.jpg",
          audioUrl: "Audios/P4/hinh3.e2.p4.mp3",
        },
      ],
    ],
  },
};

export default json;
