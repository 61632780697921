import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Match_Write from "../../components/ExcerciseTypes/Match_Write";
const json = {
  1: {
    unit: "Review 2",
    id: "SB3V2-R2-P50-E1",
    audio: "",
    video: "",
    component: D1,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page50/E1/Key/answerKey.png",
    maxLength: 1,
    // isAllowSubmit: false,
    fontSize: 26,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page50/E1/22.jpg" }],
      [{ url: "img/FriendsPlus/Page50/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page50/E1/2.jpg" },
        { url: "img/FriendsPlus/Page50/E1/3.jpg", input: true, answer: "D" },
        { url: "img/FriendsPlus/Page50/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page50/E1/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page50/E1/6.jpg" },
        { url: "img/FriendsPlus/Page50/E1/7.jpg", input: true, answer: "F" },
        { url: "img/FriendsPlus/Page50/E1/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page50/E1/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page50/E1/10.jpg" },
        { url: "img/FriendsPlus/Page50/E1/11.jpg", input: true, answer: "C" },
        { url: "img/FriendsPlus/Page50/E1/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page50/E1/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page50/E1/14.jpg" },
        { url: "img/FriendsPlus/Page50/E1/15.jpg", input: true, answer: "b" },
        { url: "img/FriendsPlus/Page50/E1/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page50/E1/17.jpg" }],
      [
        { url: "img/FriendsPlus/Page50/E1/18.jpg" },
        { url: "img/FriendsPlus/Page50/E1/19.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page50/E1/20.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page50/E1/21.jpg" }],
    ],
  },
  2: {
    unit: "Review 2",
    id: "SB3V2-R2-P50-E2",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page50/E2/Key/answerKey.png",
    maxLength: 17,
    // isAllowSubmit: false,
    fontSize: 26,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page50/E2/14.jpg" }],
      [{ url: "img/FriendsPlus/Page50/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page50/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page50/E2/3.jpg",
          input: true,
          answer: "like fish",
        },
      ],
      [{ url: "img/FriendsPlus/Page50/E2/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page50/E2/5.jpg" },
        {
          url: "img/FriendsPlus/Page50/E2/6.jpg",
          input: true,
          answer: "don't like rice",
        },
      ],
      [{ url: "img/FriendsPlus/Page50/E2/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page50/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page50/E2/9.jpg",
          input: true,
          answer: "like dogs",
        },
      ],
      [{ url: "img/FriendsPlus/Page50/E2/10.jpg" }],
      [
        { url: "img/FriendsPlus/Page50/E2/11.jpg" },
        {
          url: "img/FriendsPlus/Page50/E2/12.jpg",
          input: true,
          answer: "don't like lions",
        },
      ],
      [{ url: "img/FriendsPlus/Page50/E2/13.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Review 2",
    id: "SB3V2-R2-P50-E3",
    audio: "Audios/P50/audio-e3-p50.mp3",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page50/E3/Key/answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/E2/Key/answerKey.png',
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px",
          fontWeight: 600,
          color: "",
          fontSize: 27,
          borderRadius: "20px",
        },
        selectWordStyle: { border: "2px solid #2eb6e1" },
        limitSelect: 1,
        listWords: [
          "curly / straight",
          "long / short",
          "tall / short",
          "blue / black",
        ],
        answers: ["0-0", "1-4", "2-0", "3-0"],
        initialValue: [],
      },
      Layout: `
      <img src='img/FriendsPlus/Page50/E3/1.jpg' >
      <div style='display: flex;margin: 30px;'>
        <div style='margin-right:100px'><b>1.</b><input id='0'  type='Circle' /></div> 
        <div><b>3.</b><input id='2'  type='Circle' /></div> 
      </div>
      <div style='display:flex;margin:30px;'>
        <div style='margin-right:140px'><b>2.</b><input id='1'  type='Circle' /></div> 
        <div><b>4.</b><input id='3'  type='Circle' /></div> 
      </div>
          
        `,
    },
  },
  4: {
    unit: "Review 2",
    id: "SB3V2-R2-P50-E1",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page50/E4/Key/answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title:
          "<img src='img/FriendsPlus/Page50/E4/15.jpg' /> <br> <img src='img/FriendsPlus/Page50/E4/1.jpg' /> ",
        color: "",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "41px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "235px",
              left: "350px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "235px",
              left: "580px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
        ],
        answers: ["0-5", "1-4", "2-7", "3-6"],
        initialValue: [],
      },
      Layout: `
        <div style="font-size: 30px;">
					

          <div style='display: flex;padding-top: 5mm'>
            <div ><b>1</b> I don’t like fish.</div>
						<div><input id='5' type= 'boxMatch' /></div>
            <div><input id='1' type= 'boxMatch' /></div>
						<div style='margin-left: 380px;'><b>a.</b> They are not my dogs.</div>
          </div>

					<div style='display: flex;padding-top: 5mm'>
            <div><b>2</b> It isn’t a banana.</div>
						<div><input id='2' type= 'boxMatch' /></div>
            <div><input id='3' type= 'boxMatch' /></div>
						<div style='margin-left: 359px;'><b>b.</b> I do not have blue eyes.</div>
          </div>
					
					<div style='display: flex;padding-top: 5mm'>
            <div ><b>3</b>  They aren’t my dogs.</div>
						<div><input id='4' type= 'boxMatch' /></div>
            <div><input id='0' type= 'boxMatch' /></div>
						<div style='margin-left: 300px;'><b>c.</b> I do not like fish.</div>
          </div>
					<div style='display: flex;padding-top: 5mm'>
            <div ><b>4</b> I don’t have blue eyes.</div>
						<div><input id='6' type= 'boxMatch' /></div>
            <div><input id='7' type= 'boxMatch' /></div>
						<div style='margin-left: 278px;'><b>d.</b> It is not a banana.</div>
          </div>
        </div>
      `,
    },
  },
  // 4: {
  //   unit: 'Review 2',
  //   id: 'SB3V2-R2-P50-E1',
  //   audio: '',
  //   video: '',
  //   component: Match_Write,
  //   exerciseKey: 'img/FriendsPlus/Page50/E4/Key/answerKey.png',
  //   titleQuestion: [
  //     {
  //       num: '',
  //       title: "<img src='img/FriendsPlus/Page50/E4/15.jpg' /> <br> <img src='img/FriendsPlus/Page50/E4/1.jpg' /> ",
  //       color: '',
  //     },
  //   ],
  //   question: {
  //     Match: {
  //       answers: ['0-5', '1-4', '2-7', '3-6'],
  //       coordinates: [
  //         { x: 222, y: 200 },
  //         { x: 444, y: 200 },
  //         { x: 666, y: 200 },
  //         { x: 888, y: 200 },
  //         { x: 222, y: 400 },
  //         { x: 444, y: 400 },
  //         { x: 666, y: 400 },
  //         { x: 888, y: 400 },
  //       ],
  //       // height: 600,
  //       isHorizontal: false,
  //       width: 1110,
  //     },
  //     Write: {
  //       answers: ['hello', 'hi', 'now', 'future'],
  //       commonStyles: {},
  //     },
  //     Layout: `
  //       <div>
  //       <input id='0' />
  //       <input id='1' />
  //       <canvas></canvas>
  //       <input id='2' />
  //       <input id='3' width='50px' />
  //       </div>
  //     `,
  //   },
  // },
};
export default json;
