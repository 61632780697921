import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Fluency Timel 2",
    id: "SB3V2-FT2-P52-E1",
    audio: "Audios/P52/audio-e1-p51.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page52/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page52/E1/2.jpg",
          audioUrl: "Audios/P52/tieude-e1-p51.mp3",
        },
        { url: "img/FriendsPlus/Page52/E1/3.jpg" },
      ],
      [
        // Column1
        {
          url: "img/FriendsPlus/Page52/E1/4.jpg",
          audioUrl: "Audios/P52/hinh1-e1.mp3",
        },
        {
          url: "img/FriendsPlus/Page52/E1/5.jpg",
          audioUrl: "Audios/P52/hinh2-e1.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Fluency Timel 2",
    id: "SB3V2-FT2-P52-E2",
    audio: "Audios/P52/audio-e2-p51.mp3",
    exerciseKey: "img/FriendsPlus/Page52/E2/Key/answerKey.png",
    video: "",
    component: D1,
    maxLength: 1,
    fontSize: 23,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page52/E2/11.jpg" },
        {
          url: "img/FriendsPlus/Page52/E2/12.jpg",
          audioUrl: "Audios/P52/tieude-e2-p51.mp3",
        },
        { url: "img/FriendsPlus/Page52/E2/13.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page52/E2/0.jpg" }],
      [
        { url: "img/FriendsPlus/Page52/E2/1.jpg" },
        { url: "img/FriendsPlus/Page52/E2/2.jpg", input: true, answer: "1" },
        { url: "img/FriendsPlus/Page52/E2/3.jpg" },
        { url: "img/FriendsPlus/Page52/E2/4.jpg", input: true, answer: "2" },
        { url: "img/FriendsPlus/Page52/E2/5.jpg" },
        { url: "img/FriendsPlus/Page52/E2/6.jpg", input: true, answer: "4" },
        { url: "img/FriendsPlus/Page52/E2/7.jpg" },
        { url: "img/FriendsPlus/Page52/E2/8.jpg", input: true, answer: "3" },
        { url: "img/FriendsPlus/Page52/E2/9.jpg" },
      ],

      [{ url: "img/FriendsPlus/Page52/E2/10.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Fluency Timel 2",
    id: "SB3V2-FT2-P52-E3",
    audio: "img/FriendsPlus/Page52/Audio/audio-e1-p52.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page52/E3/1.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page52/E3/2.jpg" },
      ],
    ],
  },
};

export default json;
