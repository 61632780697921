import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 1",
    id: "SB3V2-U1-P12-E1",
    audio: "Audios/P12/audio.e1.p12.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page12/E1/8.jpg" },
        {
          url: "img/FriendsPlus/Page12/E1/9.jpg",
          audioUrl: "Audios/P12/tieude.e1.p12.mp3",
        },
        { url: "img/FriendsPlus/Page12/E1/10.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page12/E1/1.jpg",
          audioUrl: "Audios/P12/kite.mp3",
        },
        { url: "img/FriendsPlus/Page12/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page12/E1/3.jpg",
          audioUrl: "Audios/P12/bike.mp3",
        },
        { url: "img/FriendsPlus/Page12/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page12/E1/5.jpg",
          audioUrl: "Audios/P12/train.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB3V2-U1-P12-E2",
    audio: "Audios/P12/audio.e2.p12.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page12/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page12/E2/5.jpg",
          audioUrl: "Audios/P12/tieude.e2.p12.mp3",
        },
        { url: "img/FriendsPlus/Page12/E2/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page12/E3/2.jpg" }],
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB3V2-U1-P12-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page12/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page12/E3/2.jpg" }],
    ],
  },
};

export default json;
