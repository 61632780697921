import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "SB3V2-U6-P46-E1",
    audio: "Audios/P46/audio-e1-p46.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page46/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page46/E1/b.jpg",
          audioUrl: "Audios/P46/tieude-e1-p46.mp3",
        },
        { url: "img/FriendsPlus/Page46/E1/c.jpg" },
      ],
      [
        // Column2
        {
          url: "img/FriendsPlus/Page46/E1/1.jpg",
          audioUrl: "Audios/P46/tall.mp3",
        },
        { url: "img/FriendsPlus/Page46/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page46/E1/3.jpg",
          audioUrl: "Audios/P46/short.mp3",
        },
        { url: "img/FriendsPlus/Page46/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page46/E1/5.jpg",
          audioUrl: "Audios/P46/thin.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 6",
    id: "SB3V2-U6-P46-E1",
    audio: "Audios/P46/audio-e2-p46.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page46/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page46/E2/2.jpg",
          audioUrl: "Audios/P46/tieude-e2-p46.mp3",
        },
        { url: "img/FriendsPlus/Page46/E2/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page46/E3/2.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 6",
    id: "SB3V2-U6-P46-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row
      [
        // Column2
        { url: "img/FriendsPlus/Page46/E3/1.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page46/E3/2.jpg" }],
    ],
  },
};

export default json;
