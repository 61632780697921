import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  3: {
    // Exercise num
    unit: "Review 4",
    id: "SB3V2-RV4-P95-E3",
    audio: "",
    video: "",
    textAlign: "center",
    component: T6,
    inputSize: 100,
    titleImage: "img/FriendsPlus/Page95/E3/5.jpg",
    exerciseKey: "img/FriendsPlus/Page95/E3/Key/answerKey.png",

    questionImage: [],
    checkUppercase: true,
    maxLength: 7,
    hintBox: [
      {
        src: ["these", "those"],
        borderColor: "rgb(82,197,235)",
        border: "4px solid",
        width: 260,
      },
    ],
    questions: [
      {
        title: `
          <div style='margin:20px 40px'><hintBox id='0'></hintBox></div>
          <div style='display: flex;justify-content: space-around'>
            <div>
              <img src='img/FriendsPlus/Page95/E3/1.jpg'>
              <div style='width:5cm;margin-left:30px'>
                #are computers.
              </div>
            </div>
            <div style='margin: 0px 10px'>
              <img src='img/FriendsPlus/Page95/E3/2.jpg'>
              <div style='width:5cm;margin-left:30px'>
                #are chairs.
              </div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page95/E3/3.jpg'>
              <div style='width:5cm;margin-left:30px'>
                #are pencils.
              </div>
            </div>
            <div style='margin: 0px 10px'>
              <img src='img/FriendsPlus/Page95/E3/4.jpg'>
              <div style='width:5cm;margin-left:30px'>
                #are bags.
              </div>
            </div>
          </div>        
        
        
        
        `,
        answer: ["Those", "Those", "Those", "These"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Review 4",
    id: "SB3V2-RV4-P95-E4",
    audio: "",
    video: "",
    component: DesignUnderLine,
    // component: DesignUnderLine1,
    totalInput: 5,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page95/E4/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page95/E4/31.jpg" }],
      [
        // row1
        { url: "img/FriendsPlus/Page95/E4/1.jpg" },
      ],
      [
        // row2
        { url: "img/FriendsPlus/Page95/E4/2.jpg" },
        { url: "img/FriendsPlus/Page95/E4/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page95/E4/4.jpg" },
        { url: "img/FriendsPlus/Page95/E4/5.jpg", input: 1 },
        { url: "img/FriendsPlus/Page95/E4/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page95/E4/7.jpg" }],
      [
        // row3
        { url: "img/FriendsPlus/Page95/E4/8.jpg" },
        { url: "img/FriendsPlus/Page95/E4/9.jpg", input: 2 },
        { url: "img/FriendsPlus/Page95/E4/10.jpg" },
        { url: "img/FriendsPlus/Page95/E4/11.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page95/E4/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page95/E4/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page95/E4/14.jpg" },
        { url: "img/FriendsPlus/Page95/E4/15.jpg", input: 3 },
        { url: "img/FriendsPlus/Page95/E4/16.jpg" },
        { url: "img/FriendsPlus/Page95/E4/17.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page95/E4/18.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page95/E4/19.jpg" }],
      [
        // row5
        { url: "img/FriendsPlus/Page95/E4/20.jpg" },
        { url: "img/FriendsPlus/Page95/E4/21.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page95/E4/22.jpg" },
        { url: "img/FriendsPlus/Page95/E4/23.jpg", input: 4 },
        { url: "img/FriendsPlus/Page95/E4/24.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page95/E4/25.jpg" }],
      [
        // row6
        { url: "img/FriendsPlus/Page95/E4/26.jpg" },
        { url: "img/FriendsPlus/Page95/E4/27.jpg", input: 5 },
        { url: "img/FriendsPlus/Page95/E4/28.jpg" },
        { url: "img/FriendsPlus/Page95/E4/29.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page95/E4/30.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Review 4",
    id: "SB3V2-RV4-P95-E5",
    audio: "",
    video: "",
    component: UI,
    // component: DesignUnderLine1,
    titleImage: "",
    recorder: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page95/E5/1.jpg" }],
      [
        // row1
        { url: "img/FriendsPlus/Page95/E5/2.jpg" },
      ],
    ],
  },
};
export default json;
