import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P65-E1",
    audio: "Audios/P65/audio-e1-p65.mp3",
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page65/E1/Key/answerKey.png",
    video: "",
    component: D1,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page65/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page65/E1/b.jpg",
          audioUrl: "Audios/P65/tieude-e1-p65.mp3",
        },
        { url: "img/FriendsPlus/Page65/E1/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page65/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page65/E1/2.jpg" },
        { url: "img/FriendsPlus/Page65/E1/3.jpg", input: true, answer: "3" },
        { url: "img/FriendsPlus/Page65/E1/4.jpg" },
        { url: "img/FriendsPlus/Page65/E1/5.jpg", input: true, answer: "2" },
        { url: "img/FriendsPlus/Page65/E1/6.jpg" },
        { url: "img/FriendsPlus/Page65/E1/7.jpg", input: true, answer: "4" },
        { url: "img/FriendsPlus/Page65/E1/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page65/E1/9.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P65-E1",
    video: "",
    component: D1,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page65/E2/1.jpg" }],
      [{ url: "img/FriendsPlus/Page65/E2/2.jpg" }],

      [{ url: "img/FriendsPlus/Page65/E2/3.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P65-E1",
    audio: "img/FriendsPlus/Page58/Audio/audio-e2-p58.mp3",
    video: "",
    component: D1,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page65/E3/1.jpg" }],
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P65-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page65/E4/Key/answerKey.png",
    inputSize: 80,
    isHiddenCheck: true,
    checkUppercase: true,
    fontSize: 25,
    maxLength: 4,
    titleImage: "img/FriendsPlus/Page65/E4/1.jpg",
    questions: [
      {
        title: `
        <div style='margin-bottom:10px'>I’m eat# some cake.</div>
        <div style='margin-bottom:10px'>she’s drink#water.</div>
        <div style='margin-bottom:10px'>He’s talk#with his family at the party.</div>
        `,
        answer: ["ing", "ing", "ing"],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P65-E5",
    audio: "",
    video: "",
    component: T6,
    // exerciseKey: "img/FriendsPlus/Page9/E2/Key/answerKey.png",
    inputSize: 200,
    checkUppercase: true,
    fontSize: 25,
    checkDuplicated: true,
    titleImage: "img/FriendsPlus/Page65/E5/1.jpg",
    questions: [
      {
        title: `
        <div style='margin-bottom:10px'>It’s Tet holiday. My dad is#. My mom is #.</div>
        <div style='margin-bottom:10px'>I’m. We’re happy.</div>
        `,
        answer: [
          "holiday",
          "mom ",
          " dad",
          "Aunt Tam / her aunt",
          "red",
          "brother",
        ],
      },
    ],
  },
};

export default json;
