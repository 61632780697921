import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    unit: "Unit 5",
    id: "SB3V2-U5-P40-E1",
    audio: "Audios/P40/audio-e1-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page40/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page40/E1/2.jpg",
          audioUrl: "Audios/P40/tieude-e1-p40.mp3",
        },
        { url: "img/FriendsPlus/Page40/E1/3.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page40/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page40/E1/5.jpg",
          audioUrl: "Audios/P40/milk.mp3",
        },
        { url: "img/FriendsPlus/Page40/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page40/E1/7.jpg",
          audioUrl: "Audios/P40/juice.mp3",
        },
        { url: "img/FriendsPlus/Page40/E1/8.jpg" },
        {
          url: "img/FriendsPlus/Page40/E1/9.jpg",
          audioUrl: "Audios/P40/water.mp3",
        },
        { url: "img/FriendsPlus/Page40/E1/10.jpg" },
      ],
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB3V2-U5-P40-E2",
    audio: "Audios/P40/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page40/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page40/E2/2.jpg",
          audioUrl: "Audios/P40/tieude-e2-p40.mp3",
        },
        { url: "img/FriendsPlus/Page40/E2/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page40/E3/2.jpg" }],
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB3V2-U5-P40-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [{ url: "img/FriendsPlus/Page40/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page40/E3/2.jpg" }],
    ],
  },
};

export default json;
