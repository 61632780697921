import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
const json = {
  1: {
    unit: "Unit 6",
    id: "SB3V2-U6-P49-E1",
    audio: "Audios/P49/audio-e1-p49.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page48/E1/Key/answerKey.png",
    component: D1,
    recorder: true,
    questionImage: [
      [
        // Column1
        { url: "img/FriendsPlus/Page49/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page49/E1/b.jpg",
          audioUrl: "Audios/P49/tieude-e1-p49.mp3",
        },
        { url: "img/FriendsPlus/Page49/E1/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page49/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page49/E1/2.jpg" },
        { url: "img/FriendsPlus/Page49/E1/3.jpg", input: true, answer: "2" },
        { url: "img/FriendsPlus/Page49/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page49/E1/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page49/E1/6.jpg" },
        { url: "img/FriendsPlus/Page49/E1/7.jpg", input: true, answer: "3" },
        { url: "img/FriendsPlus/Page49/E1/8.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page49/E1/9.jpg" },
        { url: "img/FriendsPlus/Page49/E1/10.jpg", input: true, answer: "4" },
        { url: "img/FriendsPlus/Page49/E1/11.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page49/E1/12.jpg" }],
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB3V2-U6-P49-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [{ url: "img/FriendsPlus/Page49/E2/1.jpg" }],
      [{ url: "img/FriendsPlus/Page49/E2/2.jpg" }],
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB3V2-U6-P49-E3",
    audio: "img/FriendsPlus/Page42/Audio/audio-e2-p42.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [[{ url: "img/FriendsPlus/Page49/E3/1.jpg" }]],
  },
  4: {
    // Exercise num
    unit: "Unit 6",
    id: "SB3V2-U6-P49-E4",
    component: Circle_Write,
    // exerciseKey: 'img/FriendsPlus/Page39/E2/Key/answerKey.png',
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px",
          fontWeight: 600,
          color: "",
          fontSize: 27,
          borderRadius: "20px",
          marginInline: "-1px",
        },
        selectWordStyle: { border: "2px solid #25478f" },
        limitSelect: 1,
        listWords: [
          `c urly straight`,
          "long / short",
          "tall / short",
          "blue / black",
        ],
        answers: ["0-0", "1-4", "2-0", "3-4"],
        initialValue: [],
      },
      Layout: /*html*/ `
      <img src='img/FriendsPlus/Page49/E4/1.jpg'>
      <div style='display: flex;margin: 30px;'>
        <div style='margin-right:100px'><b>1.</b>
           <span style='margin-left:10px'><input id='0'  type='Circle' /></span> 
        </div> 
        <div><b>3.</b>
           <span style='margin-left:10px'><input id='2'  type='Circle' /></span> 
        </div> 
      </div>
      <div style='display:flex;margin:30px;'>
        <div style='margin-right:140px'><b>2.</b><input id='1'  type='Circle' /></div> 
        <div><b>4.</b><input id='3'  type='Circle' /></div> 
      </div>
          
        `,
    },
  },
  5: {
    unit: "Unit 6",
    id: "SB3V2-U6-P49-E5",
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true,
    inputSize: 310,
    maxLength: 30,
    questionImage: [],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page49/E5/11.jpg' />
        <div style='display: flex; margin-top: 10px '>
          <div style='margin:0px 0px 0px 50px'><b>1.</b> I don’t have#.</div>
          <div style='margin-left: 100px'><b>3.</b> He / She has#.</div>
        </div>
        <div style='display: flex '>
          <div style='margin:0px 0px 0px 50px '><b>2.</b> I#.</div>
          <div style='margin:0px 0px 0px 218px;'><b>4.</b> He / She#.</div>
        </div>
        `,
        answer: ["", "", "", ""],
      },
    ],
  },
};

export default json;
