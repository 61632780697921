import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 5",
    id: "SB3V2-U5-P39-E1",
    audio: "Audios/P39/audio-e1-p39.mp3",
    video: "Videos/Video-e3-p38.mp4",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page39/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page39/E1/2.jpg",
          audioUrl: "Audios/P38/tieude-e3-p38.mp3",
        },
        { url: "img/FriendsPlus/Page39/E1/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page38/E3/1.jpg",
          audioUrl: "Audios/P38/hinh1-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E3/2.jpg",
          audioUrl: "Audios/P38/hinh2-e3.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page38/E3/3.jpg",
          audioUrl: "Audios/P38/hinh3-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E3/4.jpg",
          audioUrl: "Audios/P38/hinh4-e3.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB3V2-U5-P39-E2",
    audio: "Audios/P39/audio-e2-p39.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page39/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page39/E2/2.jpg",
          audioUrl: "Audios/P39/tieude-e2-p39.mp3",
        },
        { url: "img/FriendsPlus/Page39/E2/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page39/E2/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page39/E2/5.jpg" },
        {
          url: "img/FriendsPlus/Page39/E2/6.jpg",
          audioUrl: "Audios/P39/hinh1-e2.mp3",
        },
        { url: "img/FriendsPlus/Page39/E2/7.jpg" },
        {
          url: "img/FriendsPlus/Page39/E2/8.jpg",
          audioUrl: "Audios/P39/hinh2-e2.mp3",
        },
        { url: "img/FriendsPlus/Page39/E2/9.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page39/E2/10.jpg" }],
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB3V2-U5-P39-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [[{ url: "img/FriendsPlus/Page39/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 5",
    id: "SB3V2-U5-P39-E4",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page39/E4/Key/answerKey.png",
    isAllowSubmit: false,
    fontSize: 30,
    inputHeight: 50,
    maxLength: 7,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page39/E4/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page39/E4/2.jpg" },
        { url: "img/FriendsPlus/Page39/E4/3.jpg", input: true, answer: "Yes" },
        { url: "img/FriendsPlus/Page39/E4/4.jpg" },
        { url: "img/FriendsPlus/Page39/E4/5.jpg", input: true, answer: "I do" },
        { url: "img/FriendsPlus/Page39/E4/6.jpg" },
        { url: "img/FriendsPlus/Page39/E4/7.jpg", input: true, answer: "No" },
        { url: "img/FriendsPlus/Page39/E4/8.jpg" },
        {
          url: "img/FriendsPlus/Page39/E4/9.jpg",
          input: true,
          answer: "I don't",
        },
        { url: "img/FriendsPlus/Page39/E4/10.jpg" },
      ],
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB3V2-U5-P39-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [[{ url: "img/FriendsPlus/Page39/E5/1.jpg" }]],
  },
};

export default json;
