import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB3V2-U2-P18-E1",
    audio: "Audios/P18/audio.e1.p18.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page18/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page18/E1/2.jpg",
          audioUrl: "Audios/P18/tieude.e1.p18.mp3",
        },
        { url: "img/FriendsPlus/Page18/E1/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page18/E1/4.jpg",
          audioUrl: "Audios/P18/bag.mp3",
        },
        { url: "img/FriendsPlus/Page18/E1/5.jpg" },
        {
          url: "img/FriendsPlus/Page18/E1/6.jpg",
          audioUrl: "Audios/P18/door.mp3",
        },
        { url: "img/FriendsPlus/Page18/E1/7.jpg" },
        {
          url: "img/FriendsPlus/Page18/E1/8.jpg",
          audioUrl: "Audios/P18/window.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB3V2-U2-P18-E2",
    audio: "Audios/P18/audio.e2.p18.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page18/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page18/E2/2.jpg",
          audioUrl: "Audios/P18/tieude.e2.p18.mp3",
        },
        { url: "img/FriendsPlus/Page18/E2/3.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page18/E3/2.jpg" }],
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB3V2-U2-P18-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page18/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page18/E3/2.jpg" }],
    ],
  },
};

export default json;
