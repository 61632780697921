import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DrawColor from "../../components/ExcerciseTypes/DrawColor";

const json = {
  1: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P83-E1",
    audio: "Audios/P82/audio-e3-p82.mp3",
    video: "Videos/Uni/.mp4",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '4', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page83/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page83/E1/b.jpg",
          audioUrl: "Audios/P82/tieude-e3-p82.mp3",
        },
        { url: "img/FriendsPlus/Page83/E1/c.jpg" },
      ],
      [
        // Column1
        {
          url: "img/FriendsPlus/Page82/E3/1.jpg",
          audioUrl: "Audios/P82/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E3/2.jpg",
          audioUrl: "Audios/P82/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page82/E3/3.jpg",
          audioUrl: "Audios/P82/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E3/4.jpg",
          audioUrl: "Audios/P82/hinh4.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P83-E2",
    audio: "Audios/P83/audio-e2-p83.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page83/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page83/E2/b.jpg",
          audioUrl: "Audios/P83/tieude-e2-p83.mp3",
        },
        { url: "img/FriendsPlus/Page83/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page83/E2/1.jpg" }],

      [
        { url: "img/FriendsPlus/Page83/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page83/E2/3.jpg",
          audioUrl: "Audios/P83/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page83/E2/4.jpg",
          audioUrl: "Audios/P83/hinh2.mp3",
        },
        {
          url: "img/FriendsPlus/Page83/E2/5.jpg",
          audioUrl: "Audios/P83/hinh3.mp3",
        },
        { url: "img/FriendsPlus/Page83/E2/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page83/E2/7.jpg" }],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P83-E3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page83/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page83/E3/2.jpg" }],
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 11",
    id: "SB3V2-U11-P83-E4",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck:true,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page83/E4/Key/answerKey.png",
    inputSize: 150,
    titleImage: "img/FriendsPlus/Page83/E4/1.jpg",
    maxLength: 9,
    questionImage: [],
    // hintBox: [
    //   {
    //     src: [
    //       "<s style='color: rgb(0 188 212)'>brushing my hair</s>",
    //       "washing the car",
    //       "making cakes",
    //       "taking photos",
    //     ],
    //     borderColor: "#468dcb",
    //     width: 600,
    //   },
    // ],
    questions: [
      {
        title: `
        <divs style='border: 2px solid black ; padding: 5px 25px; text-align: center;margin: 20px 20px; border-radius: 5px;'>There’s&emsp;&emsp;There are</divs>
        <div style='margin:20px 0px 10px 0px'><b>1.</b># a doll on the cabinet.</div>
        <div style='margin-bottom:10px'><b>2.</b># some pillows on the bed.</div>
        <div style='margin-bottom:10px'><b>3.</b># a lot of books on the shelf.</div>
        <div style='margin-bottom:10px'><b>4.</b># a lot of balls on the rug.</div>
        <div ><b>5.</b># a blanket on the bed.</div>
        `,
        answer: ["There's", "There are", "There are", "There are", "There's"],
      },
    ],
  },

  5: {
    // Exercise num
    unit: "Unit 11",
    id: "SB5-U11-P83-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '5', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page83/E5/1.jpg" },
      ],
    ],
  },
};

export default json;
