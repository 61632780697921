import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "SB3V2-U4-P34-E1",
    audio: "Audios/P34/audio-e1-p34.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [
        // Column1
        { url: "img/FriendsPlus/Page34/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page34/E1/b.jpg",
          audioUrl: "Audios/P34/tieude-e1-p34.mp3",
        },
        { url: "img/FriendsPlus/Page34/E1/c.jpg" },
      ],
      [
        // Column2
        {
          url: "img/FriendsPlus/Page34/E1/1.jpg",
          audioUrl: "Audios/P34/bird.mp3",
        },
        { url: "img/FriendsPlus/Page34/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page34/E1/3.jpg",
          audioUrl: "Audios/P34/cat.mp3",
        },
        { url: "img/FriendsPlus/Page34/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page34/E1/5.jpg",
          audioUrl: "Audios/P34/dog.mp3",
        },
        { url: "img/FriendsPlus/Page34/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page34/E1/7.jpg",
          audioUrl: "Audios/P34/fish.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 4",
    id: "SB3V2-U4-P34-E2",
    audio: "Audios/P34/audio-e2-p34.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      // Row

      [
        // Column1
        { url: "img/FriendsPlus/Page34/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page34/E2/2.jpg",
          audioUrl: "Audios/P34/tieude-e2-p34.mp3",
        },
        { url: "img/FriendsPlus/Page34/E2/3.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page34/E3/2.jpg" },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 4",
    id: "SB3V2-U4-P34-E3",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page34/E3/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page34/E3/2.jpg" },
      ],
    ],
  },
};

export default json;
