import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    unit: "Unit 3",
    id: "SB3V2-U3-P22-E1",
    audio: "Audios/P22/audio.e1.p22.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page22/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page22/E1/2.jpg",
          audioUrl: "Audios/P22/tieude.e1.p22.mp3",
        },
        { url: "img/FriendsPlus/Page22/E1/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page22/E2/4.jpg",
          audioUrl: "Audios/P22/mom.mp3",
        },
        { url: "img/FriendsPlus/Page22/E2/5.jpg" },
        {
          url: "img/FriendsPlus/Page22/E2/6.jpg",
          audioUrl: "Audios/P22/dad.mp3",
        },
        { url: "img/FriendsPlus/Page22/E2/7.jpg" },
        {
          url: "img/FriendsPlus/Page22/E2/8.jpg",
          audioUrl: "Audios/P22/sister.mp3",
        },
        { url: "img/FriendsPlus/Page22/E2/9.jpg" },
        {
          url: "img/FriendsPlus/Page22/E2/10.jpg",
          audioUrl: "Audios/P22/brother.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB3V2-U3-P22-E2",
    audio: "Audios/P22/audio.e2.p22.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page22/E2/1.jpg" },
        {
          url: "img/FriendsPlus/Page22/E2/2.jpg",
          audioUrl: "Audios/P22/tieude.e2.p22.mp3",
        },
        { url: "img/FriendsPlus/Page22/E2/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page22/E2/4.jpg",
          audioUrl: "Audios/P22/mom.mp3",
        },
        { url: "img/FriendsPlus/Page22/E2/5.jpg" },
        {
          url: "img/FriendsPlus/Page22/E2/6.jpg",
          audioUrl: "Audios/P22/dad.mp3",
        },
        { url: "img/FriendsPlus/Page22/E2/7.jpg" },
        {
          url: "img/FriendsPlus/Page22/E2/8.jpg",
          audioUrl: "Audios/P22/sister.mp3",
        },
        { url: "img/FriendsPlus/Page22/E2/9.jpg" },
        {
          url: "img/FriendsPlus/Page22/E2/10.jpg",
          audioUrl: "Audios/P22/brother.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB3V2-U3-P22-E3",
    audio: "Audios/P22/audio.e3.p22.mp3",
    video: "/img/FriendsPlus/Page22/Video/Unit03.mp4",
    component: UI,
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page22/E3/1.jpg" },
        {
          url: "img/FriendsPlus/Page22/E3/2.jpg",
          audioUrl: "Audios/P22/tieude.e3.p22.mp3",
        },
        { url: "img/FriendsPlus/Page22/E3/3.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page22/E3/4.jpg",
          audioUrl: "Audios/P22/hinh1.e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page22/E3/5.jpg",
          audioUrl: "Audios/P22/hinh2.e3.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page22/E3/6.jpg",
          audioUrl: "Audios/P22/hinh3.e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page22/E3/7.jpg",
          audioUrl: "Audios/P22/hinh4.e3.mp3",
        },
      ],
    ],
  },
};

export default json;
