import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P55-E5",
    audio: "Audios/P54/audio-e3-p53.mp3",
    video: "/img/FriendsPlus/Page54/Video/Unit07.mp4",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page55/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page55/E1/2.jpg",
          audioUrl: "Audios/P54/tieude-e3-p53.mp3",
        },
        { url: "img/FriendsPlus/Page55/E1/3.jpg" },
      ],
      [
        // Column1
        {
          url: "img/FriendsPlus/Page54/E3/1.jpg",
          audioUrl: "Audios/P54/hinh1-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E3/2.jpg",
          audioUrl: "Audios/P54/hinh2-e3.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page54/E3/3.jpg",
          audioUrl: "Audios/P54/hinh3-e3.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E3/4.jpg",
          audioUrl: "Audios/P54/hinh4-e3.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P55-E5",
    audio: "Audios/P55/audio-e2-p54.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        { url: "img/FriendsPlus/Page55/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page55/E2/b.jpg",
          audioUrl: "Audios/P55/tieude-e2-p54.mp3",
        },
        { url: "img/FriendsPlus/Page55/E2/c.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page55/E2/1.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page55/E2/2.jpg", },
        {
          url: "img/FriendsPlus/Page55/E2/3.jpg",          audioUrl: "Audios/P55/hinh1-e2.mp3",
        },
        { url: "img/FriendsPlus/Page55/E2/4.jpg", },
        {
          url: "img/FriendsPlus/Page55/E2/5.jpg",          audioUrl: "Audios/P55/hinh2-e2.mp3",
        },
        { url: "img/FriendsPlus/Page55/E2/6.jpg",          audioUrl: "Audios/P55/hinh3-e2.mp3", },
        {
          url: "img/FriendsPlus/Page55/E2/7.jpg",
        },
        
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page55/E2/8.jpg" },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P55-E3",
    audio: "img/FriendsPlus/Page54/Audio/audio-e1-p54.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        // Column5
        { url: "img/FriendsPlus/Page55/E3/1.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page55/E3/2.jpg" },
      ],
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P55-E4",
    audio: "",
    video: "",
    component: D1,
    fontSize: 32,
    inputHeight: 37,
    exerciseKey: "img/FriendsPlus/Page55/E4/Key/answerKey.png",
    checkUppercase: true,
    textAlign:"center",
    maxLength: 13,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        // Column5
        { url: "img/FriendsPlus/Page55/E4/19.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page55/E3/2.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page55/E4/1.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page55/E4/2.jpg" },
        {
          url: "img/FriendsPlus/Page55/E4/3.jpg",
          input: true,
          answer: "She's wearing",
        },
        { url: "img/FriendsPlus/Page55/E4/4.jpg" },
        {
          url: "img/FriendsPlus/Page55/E4/5.jpg",
          input: true,
          answer: "skirt",
        },
        { url: "img/FriendsPlus/Page55/E4/6.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page55/E4/7.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page55/E4/8.jpg" },
        {
          url: "img/FriendsPlus/Page55/E4/9.jpg",
          input: true,
          answer: "He's wearing",
        },
        { url: "img/FriendsPlus/Page55/E4/10.jpg" },
        {
          url: "img/FriendsPlus/Page55/E4/11.jpg",
          input: true,
          answer: "shirt",
        },
        { url: "img/FriendsPlus/Page55/E4/12.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page55/E4/13.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page55/E4/14.jpg" },
        {
          url: "img/FriendsPlus/Page55/E4/15.jpg",
          input: true,
          answer: "She's wearing",
        },
        { url: "img/FriendsPlus/Page55/E4/16.jpg" },
        {
          url: "img/FriendsPlus/Page55/E4/17.jpg",
          input: true,
          answer: "boots",
        },
        { url: "img/FriendsPlus/Page55/E4/18.jpg" },
      ],
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 7",
    id: "SB3V2-U7-P55-E5",
    audio: "img/FriendsPlus/Page54/Audio/audio-e1-p54.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row

      [
        // Column5
        { url: "img/FriendsPlus/Page55/E5/1.jpg" },
      ],
    ],
  },
};

export default json;
