import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    unit: "Stater",
    id: "SB3V2-Stater-P7-E1",
    audio: "Audios/P7/audio.e1.p7.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page7/E1/a.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/b.jpg",
          audioUrl: "Audios/P7/tieude.e1.p7.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/c.jpg" },
      ],

      [
        {
          url: "img/FriendsPlus/Page7/E1/1.jpg",
          audioUrl: "Audios/P7/A.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/3.jpg",
          audioUrl: "Audios/P7/B.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/5.jpg",
          audioUrl: "Audios/P7/C.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/7.jpg",
          audioUrl: "Audios/P7/D.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/8.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/9.jpg",
          audioUrl: "Audios/P7/E.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/10.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/11.jpg",
          audioUrl: "Audios/P7/F.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/12.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/13.jpg",
          audioUrl: "Audios/P7/G.mp3",
        },
      ],
      [{ url: "img/FriendsPlus/Page7/E1/14.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page7/E1/15.jpg",
          audioUrl: "Audios/P7/H.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/16.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/17.jpg",
          audioUrl: "Audios/P7/I.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/18.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/19.jpg",
          audioUrl: "Audios/P7/J.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/20.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/21.jpg",
          audioUrl: "Audios/P7/K.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/22.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/23.jpg",
          audioUrl: "Audios/P7/L.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/24.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/25.jpg",
          audioUrl: "Audios/P7/M.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/26.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/27.jpg",
          audioUrl: "Audios/P7/N.mp3",
        },
      ],
      [{ url: "img/FriendsPlus/Page7/E1/28.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page7/E1/29.jpg",
          audioUrl: "Audios/P7/O.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/30.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/31.jpg",
          audioUrl: "Audios/P7/P.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/32.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/33.jpg",
          audioUrl: "Audios/P7/Q.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/34.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/35.jpg",
          audioUrl: "Audios/P7/R.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/36.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/37.jpg",
          audioUrl: "Audios/P7/S.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/38.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/39.jpg",
          audioUrl: "Audios/P7/T.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/40.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/41.jpg",
          audioUrl: "Audios/P7/U.mp3",
        },
      ],
      [{ url: "img/FriendsPlus/Page7/E1/42.jpg" }],
      [
        { url: "img/FriendsPlus/Page7/E1/43.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/44.jpg",
          audioUrl: "Audios/P7/V.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/45.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/46.jpg",
          audioUrl: "Audios/P7/W.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/47.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/48.jpg",
          audioUrl: "Audios/P7/X.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/49.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/50.jpg",
          audioUrl: "Audios/P7/Y.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/51.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/52.jpg",
          audioUrl: "Audios/P7/Z.mp3",
        },
        { url: "img/FriendsPlus/Page7/E1/53.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page7/E1/54.jpg" }],
    ],
  },
  2: {
    unit: "Stater",
    id: "SB3V2-Stater-P7-E2",
    audio: "Audios/P7/audio.e2.p7.mp3",
    video: "",
    component: UI,
    question: [],
    recorder: true,
    questionImage: [
      [
        { url: "img/FriendsPlus/Page7/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page7/E2/5.jpg",
          audioUrl: "Audios/P7/tieude.e2.p7.mp3",
        },
        { url: "img/FriendsPlus/Page7/E2/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page7/E2/2.jpg" }],
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB3V2-U3-P30-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [[{ url: "img/FriendsPlus/Page30/E3/1.jpg" }]],
  },
};

export default json;
