import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P64-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page64/E1/Key/answerKey.png",
    inputSize: 4100,
    checkUppercase: true,
    fontSize: 25,
    textareaStyle: { width: 500 },
    checkDuplicated: true,
    titleImage: "img/FriendsPlus/Page64/E1/1.jpg",
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page64/E2/2.jpg'>
        <div style='margin-bottom:0px'>What is Na doing?</div>
        <div><textarea id="0" rows="3"></textarea></div>
        `,
        answer: [
          "Na is helping Mom and Dad, cleaning, choosing ao dai with Mom And Aunt Tam, and making banh chung with her brother.",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P64-E2",

    audio: "Audios/P64/audio-e2-p64.mp3",
    video: "",
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page64/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page64/E2/b.jpg",
          audioUrl: "Audios/P64/tieude-e2-p64.mp3",
        },
        { url: "img/FriendsPlus/Page64/E2/c.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page64/E2/2.jpg" },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P64-E3",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page64/E3/Key/answerKey.png",
    inputSize: 145,
    maxLength: 8,
    // checkDuplicated: true,
    titleImage: "img/FriendsPlus/Page64/E3/1.jpg",
    questions: [
      {
        title: `
        <div style='margin-bottom:5px'><b>1.</b> What is Tet? It’s a #.</div>
        <div style='margin-bottom:5px'><b>2.</b>Who is Na helping? She’s helping her#and#.</div>
        <div style='margin-bottom:5px'><b>3.</b>Who is shopping with Mom and Na?#.</div>
        <div style='margin-bottom:5px'><b>4.</b>What color is her ao dai?#.</div>
        <div style='margin-bottom:5px'><b>5.</b>Who is making banh chung with Na?It’s her#.</div>
        <img src='img/FriendsPlus/Page64/E2/2.jpg'>
        `,
        answer: [
          "holiday",
          "mom / dad",
          "mom / dad",
          "aunt Tam",
          "red",
          "brother",
        ],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 8",
    id: "SB3V2-U8-P64-E1",
    audio: "",
    video: "",
    component: T6,
    // exerciseKey: "img/FriendsPlus/Page9/E2/Key/answerKey.png",
    inputSize: 4100,
    checkUppercase: true,
    fontSize: 25,
    textareaStyle: { width: 700 },
    checkDuplicated: true,
    titleImage: "img/FriendsPlus/Page64/E4/1.jpg",
    questions: [
      {
        title: `
        <div><textarea id="0" rows="8"></textarea></div>
        `,
        answer: [
          "Na is helping Mom and Dad, cleaning, choosing ao dai with Mom And Aunt Tam, and making banh chung with her brother",
        ],
      },
    ],
  },
};

export default json;
